import React from 'react';
import { Box, Icon, Text, useRadio, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TransportOptionsCard = ({ label, icon, ...props }) => {
  const { getInputProps, getCheckboxProps, getRadioProps, htmlProps, state } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const radio = getRadioProps();

  return (
    <Box as="label" {...radio} {...htmlProps}>
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        flexDirection="row"
        alignItems="center"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        transition={state.isChecked ? "color 0.3s ease, box-shadow 0.3s ease" : "color 0.3s ease"}
        boxShadow= {state.isChecked ? "md" : "none"}
        borderColor={state.isChecked ? "blackAlpha.800" : "#bdbdbd"}
        // bg={state.isChecked ? "white" : "white"}
        color={state.isChecked ? "black" : "#bdbdbd"}
        _hover={{
          transition: "color 0.3s ease",
          borderColor: state.isChecked ? "blackAlpha.800" : "#bdbdbd",
          color: state.isChecked ? "black" : "#757575",
        }}
        p={{ base: 3, md: 2 }}
        gap={3}
      >
          <Icon fontSize="1.25rem" as={FontAwesomeIcon} icon={icon} color={state.isChecked ? "black" : "#bdbdbd"} />


        <VStack alignItems="start" spacing={0}>
          <Text fontWeight="500" fontSize="1rem">{label}</Text>
        </VStack>

      </Box>
    </Box>
  );
};

export default TransportOptionsCard;