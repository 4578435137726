import React, { useState, useEffect } from 'react';
import { Box,  Button, Flex, Heading, IconButton, SimpleGrid, Text, Image, useBreakpointValue, useDisclosure, VStack, HStack, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays,faPlus,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import LoginAlert from './LoginAlert';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; // if not already imported
import empty from './empty.png'
import { Skeleton, SkeletonCircle } from '@chakra-ui/react'
import axios from 'axios';
import NavBar from './Navbar';
import FooterSection from './landing/FoooterSection'


const SkeletonLayout = () => (
  <Box p={6}>
    <Box position="relative">
      <Skeleton width="100%" height="23vh" borderRadius="0.5rem" mb={2} />
    </Box>
    <VStack spacing={2} width="100%">
  <HStack spacing={2} width="100%">
    <SkeletonCircle width={7} height={6} padding={0} mb={6} />
    <VStack spacing={2} width="100%">
      <Skeleton height="0.8rem" width="100%" borderRadius="0.25rem" />
      <Skeleton height="0.8rem" width="100%" borderRadius="0.25rem" />
    </VStack>
  </HStack>
</VStack>
  </Box>
);



const ItineraryList = () => {
  const history = useNavigate()
  const [itineraries, setItineraries] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [ profile, setProfile ] = useState(JSON.parse(localStorage.getItem('profile')) || null);
  const isMobile = useBreakpointValue({base:true, md: false})
  const [isHovered,setIsHovered] = useState(false)
  const [fetched,setFetched] = useState(false);

  // if (!localStorage.getItem("profile")) {
  //   history("/");
  // }

  const deleteItinerary = async (itineraryId) => {
    
    try {
      const userId = JSON.parse(localStorage.getItem("profile")).id; // Assuming userId is stored in profile
      // Call the API to delete the itinerary using fetch or axios
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/deleteItinerary`, { userId, id: itineraryId });
      // Refresh the list by fetching it again
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listItinerary?userId=${userId}`);
      setItineraries(response.data.itineraries);
    } catch (e) {
      console.error("Failed to delete itinerary", e);
    }
  };

  // Responsive design: columns change based on the breakpoint
  const columns = useBreakpointValue({ base: 1, md: 4 });

  const login = useGoogleLogin({
    
    onSuccess: (codeResponse) => {
      fetch(`${process.env.REACT_APP_AUTH_URL}/api/auth/google`, {
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: codeResponse.code }),
      })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('user', JSON.stringify(data));
        // effectRan.current = false
        onClose();
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${JSON.parse(localStorage.getItem('user')).access_token}`, {
          method: 'GET',
          headers: new Headers({
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
            'Accept': 'application/json'
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
    
          return response.json();
        })
        .then(data => {
    
          setProfile(data);
          localStorage.setItem('profile', JSON.stringify(data));
    
          fetch(`${process.env.REACT_APP_AUTH_URL}/api/dbsignin`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(data),
          }).then(response => {
            console.log(response)
           
          })
    
          
        })
        .catch(error => console.log(error));
      })
      .catch(error => {
        console.error('Error:', error);
      });

    },
    accessType: 'offline',
    flow: 'auth-code',
    onError: (error) => console.log('Login Failed:', error)
  });

  const logOut = () => {
    googleLogout();
    setUser(null);
    setProfile(null);
    fetch(`${process.env.REACT_APP_AUTH_URL}/api/logout`, {
      method: 'POST',
      credentials: 'include', // Include cookies with the request
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log('Backend response:', data);
      // Proceed with any additional logout operations, such as clearing frontend state
    })
    .catch(error => {
      console.error('Error:', error);
    });
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('jwt');
    history("/")
    
  };

  useEffect(() => {
    const fetchItineraries = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("profile")).id;
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listItinerary?userId=${userId}`);
        setItineraries(response.data.itineraries);
        setFetched(true);
      } catch (error) {
        console.error("Failed to fetch itineraries", error);
      }
    };

    fetchItineraries();
  }, []);

  return (
    <>
    <NavBar profile={profile} logOut={logOut} onOpen={onOpen} />
    <LoginAlert onClose={onClose} isOpen={isOpen} login={login}></LoginAlert>
    <Flex justifyContent="space-between" alignItems="center" pt={8} px={{base: "9vw", md:"9vw"}} >
      <Heading fontSize="1.5rem" lineHeight="2.5rem">Your Trips</Heading>
       
      <Box>

      <Button
       backgroundColor="#00501E"
       borderRadius="999px"
       color="white"
       variant="solid"
       _hover={{
         backgroundColor: "#00501E",
         color: "white",
         textDecoration: "none"
       }}
         as={Link} to="/new-trip"><FontAwesomeIcon icon={faPlus} style={{"marginTop":"0px","marginRight":"5","marginBottom":"2"}} /> New Trip</Button>
      </Box>
    </Flex>

    
    {(itineraries.length > 0) && fetched &&
        <Center>
            <SimpleGrid columns={columns} spacing={2} p={{base:0,md:10}} width={{base: "100vw",md:"90vw"}}>
        {itineraries.map((itinerary) => (
          <Box key={itinerary.itineraryId} borderWidth="0px" borderRadius="lg" overflow="hidden" p={6} >
  
            <Box position="relative" onMouseEnter={() => {
            setIsHovered(itinerary.itineraryId)
          }} onMouseLeave={() => setIsHovered(null)}>
            <Link to={`/itinerary/${itinerary.itineraryId}`} style={{textDecoration:"none"}} _hover={{
          //  backgroundColor: "#00501E",
           color: "black",
           textDecoration: "none"
         }}>

<Image
            src={`https://tse2.mm.bing.net/th?q=Photo%27s+${itinerary.geoValue} ${itinerary.destination.split(',')[(itinerary.destination.split(',').length-1)].trim()} still`} // Assuming `activity.imageUrl` holds the image URL
            alt={`Image of ${itinerary.destination}`}
            width="100%"
            objectFit="cover" // This makes the image cover the defined dimensions
            h="12rem" // Makes the image take the full height of the card, adjust as needed
            borderRadius="0.5rem"
          />
   </Link>


            
           {isHovered == itinerary.itineraryId && !isMobile &&  (
          <IconButton
            aria-label="Delete itinerary"
            icon={<FontAwesomeIcon icon={faTrashAlt} size='1x' />}
            position="absolute"
            size="sm"
            height="2rem"
            top="10px"
            right="10px"
            backgroundColor="rgba(0, 0, 0, .5)"
            color="white"   
            _hover={{
                "backgroundColor":"rgba(0, 0, 0, .5)",
                "color":"white"   
            }}
            borderRadius="50%"
            onClick={() => deleteItinerary(itinerary.itineraryId)}
          />
        )}
        {isMobile &&  (
          <IconButton
            aria-label="Delete itinerary"
            icon={<FontAwesomeIcon icon={faTrashAlt} size='1x' />}
            position="absolute"
            size="sm"
            height="2rem"
            top="10px"
            right="10px"
            backgroundColor="rgba(0, 0, 0, .5)"
            color="white"   
            _hover={{
                "backgroundColor":"rgba(0, 0, 0, .5)",
                "color":"white"   
            }}
            borderRadius="50%"
            onClick={() => deleteItinerary(itinerary.itineraryId)}
          />
        )}
          </Box>
           <Link color='black' to={`/itinerary/${itinerary.itineraryId}`} style={{textDecoration:"none"}} _hover={{
          //  backgroundColor: "#00501E",
           color: "black",
           textDecoration: "none"
         }}>
         <HStack mb={2}>
            <Image alt="Profile picture for PathPal: Ai trip planner" src={profile.picture} mt={2} borderRadius="9999px" width={6} height={6}></Image>
         <Text  color='black' className='tshad' mt={1} pt={1} fontSize="lg" _hover={{
          //  backgroundColor: "#00501E",
           color: "black !important",
           fontWeight:"600",
           textDecoration: "none",
           textOverflow:"ellipsis" // add ellipsis to indicate text clipping
         }}>{`${itinerary.destination.split(',')[0].trim()}, ${itinerary.destination.split(',')[(itinerary.destination.split(',').length-1)].trim()}`}</Text>
            </HStack>
            </Link>
            <HStack gap={2}>
            <FontAwesomeIcon width="1rem" style={{"paddingBottom":"5"}} color='#9e9e9e' icon={faCalendarDays} />
            <Link to={`/itinerary/${itinerary.itineraryId}`} style={{textDecoration:"none"}} _hover={{
           color: "black",
           textDecoration: "none"
         }}>
              <Text _hover={{
           color: "black",
           textDecoration: "none"
         }}>{itinerary.dateRange.split(' to ')[0].trim()}</Text>
              </Link>
              <Link to={`/itinerary/${itinerary.itineraryId}`} style={{textDecoration:"none"}} _hover={{
           color: "black",
           textDecoration: "none"
         }}>
              <Text _hover={{
           color: "black",
           textDecoration: "none"
         }} mt={0}> • {(itinerary.days == 1) ? `${itinerary.days} Day` : `${itinerary.days} Days`}</Text>
              </Link>
              </HStack>
          </Box>
        ))}
      </SimpleGrid>
        </Center>
    }
    {(itineraries.length <= 0) && fetched && 
    <Center>
    <VStack mt="8rem">
    <Heading lineHeight="1.75rem" color="#9e9e9e !important;">We couldn't find any trips</Heading>
     <Image alt="empty picture for PathPal: Ai trip planner" width="20rem" height="20rem" src={empty}></Image>
    </VStack>
    </Center>
    }
    {(itineraries.length < 0) && 
    <Center>
    <VStack mt="8rem">
    <Heading lineHeight="1.75rem" color="#9e9e9e !important;">We couldn't find any trips</Heading>
     <Image alt="empty picture for PathPal: Ai trip planner" width="20rem" height="20rem" src={empty}></Image>
    </VStack>
    </Center>
    }

    {!fetched && 
    <>
 <Center>
   <SimpleGrid columns={columns} spacing={2} p={{base:0,md:10}} width={{ base: "100vw", md: "90vw" }}>
{Array.from({ length: 8 }).map((_, index) => (
         <SkeletonLayout key={index} />
       ))}
    </SimpleGrid>
   </Center>
    </>
    } 
    <FooterSection></FooterSection>
    </>
  );
};

export default ItineraryList;