import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isLoggedIn = localStorage.getItem("profile"); // Make sure to verify how you're storing and parsing the logged-in status.
    const location = useLocation();

    if (!isLoggedIn) {
        // Redirect them to the home page, but save the current location they were trying to go to
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute