import React, { useState } from 'react';
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useBreakpointValue,
  ModalCloseButton,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
  Center,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faCopy } from '@fortawesome/free-solid-svg-icons';


function ShareComponent({ isScrolled }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentURL] = useState(window.location.href);
  const [copyButtonText, setCopyButtonText] = useState("Copy Link");
  const isMobile = useBreakpointValue({ base: true, md: false });


  const handleCopy = () => {
    navigator.clipboard.writeText(currentURL).then(() => {
      setCopyButtonText("Link Copied!");
      setTimeout(() => setCopyButtonText("Copy Link"), 1000); // Changes text back after 3 seconds
    });
  };

  return (
    <>
      <Button onClick={onOpen} mr={2} bg={isScrolled ? "rgb(245 245 245)" : "hsla(0,0%,100%,.2)"} color={isScrolled ? 'black' : 'white'} _hover={isScrolled ? { bg: 'rgb(238 238 238)', color: "black" } : { bg: 'hsla(0,0%,100%,.5)', color: "white" }} borderRadius="9999px">
        <FontAwesomeIcon size='15' icon={faShare}  color={isScrolled ? 'black' : 'white'} />
        {!isMobile && (
          <Text ml={1}>
          Share
        </Text>
        )}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center>
            Share your trip!
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4} fontSize="1rem">
              Excited about your upcoming trip? Share the journey with your friends!
            </Text>
            <InputGroup size="md">
            <Button color="white" bg="#C8A0FF" size="md" _hover={{ color: "white", backgroundColor: "#C8A0FF" }} onClick={handleCopy}>
              {copyButtonText == "Copy Link" ? (
                <>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link" class="svg-inline--fa fa-link w-auto text-[12px] md:text-base" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"></path></svg>

                </>
              ) : (
                <>
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-check" class="svg-inline--fa fa-circle-check w-auto text-[12px] md:text-base" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path></svg>
                </>
              )
              
              }
                  <Text ml={2}>{copyButtonText}</Text>
                </Button>
            </InputGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ShareComponent;