import React, { useState } from 'react';
import { Box, useRadio, useRadioGroup, HStack, Text, Flex, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

// Custom RadioCard component with adjusted padding for less height
function RadioCard(props) {
  const { getInputProps, getCheckboxProps, htmlProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const txtColor = props.isChecked ? "white" : "#616161"

  return (
    <Box as="label" {...htmlProps}>
      <input {...input} />
      <Box
        position="relative"
        {...checkbox}
        width="3rem"
        cursor="pointer"
        borderWidth="1.2px"
        borderRadius="md"
        borderColor="rgb(224 224 224)"
        boxShadow="none"
        color="#9e9e9e"
        _checked={{
          bg: "black",
          color: "white",
        }}
        _hover={{
          backgroundColor: "#f5f5f5",
          color: {txtColor},
          _checked: {
            backgroundColor: "black",
          }
        }}
        _focus={{
          boxShadow: "none",
        }}
        // px={4} // Adjusted padding for width
        py={0.5} // Reduced padding for height
        textAlign="center"
        transition="background-color 0.3s ease"

      >
        {props.children}
        {props.isChecked && (
          <IconButton
            aria-label="Deselect"
            icon={<CloseIcon />}
            
            size="0.1rem"
            fontSize="0.35rem"
            position="absolute"
            right="2px"
            top="2px"
            mr="0.1rem"
            mt="0.1rem"
            color="white"
            backgroundColor="transparent"
            _hover={{
                backgroundColor:"transparent"
            }}
            // color="black"
            // colorScheme="whiteAlpha"
            onClick={(e) => {
              // Prevent the radio button click event
              e.preventDefault();
              // Call the `onClick` handler with empty value to deselect
              props.onDeselect();
            }}
          />
        )}
      </Box>
    </Box>
  );
}

// Component that includes the question and radio options aligned horizontally
function FirstTimeVisit() {
  const [value, setValue] = useState('');
  const options = ["Yes", "No"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'firstVisit',
    value,
    onChange: setValue,
  });

  const group = getRootProps();

  const handleDeselect = () => setValue('');

  return (
    <Box borderWidth="1px" borderRadius="md" p={2}>
      <Flex align="center" justify="space-between">
        <Text fontSize="1rem" lineHeight="1.5rem" mr={6} ml={2}>First time visiting?</Text>
        <HStack {...group}>
          {options.map((optionValue) => {
            const radio = getRadioProps({ value: optionValue });
            return (
              <RadioCard 
                key={optionValue} 
                {...radio} 
                isChecked={value === optionValue}
                onDeselect={handleDeselect}
              >
                {optionValue}
              </RadioCard>
            );
          })}
        </HStack>
      </Flex>
    </Box>
  );
}

export default FirstTimeVisit;