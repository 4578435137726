import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { HStack, VisuallyHidden, useDisclosure, } from '@chakra-ui/react'
import 'rsuite/dist/rsuite.min.css';
import { AlertDialog, Box, AlertDialogBody, Text, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from "@chakra-ui/react";
import { DateRangePicker } from 'rsuite';

import { Container, FormLabel, Heading, Divider, Button, useBreakpointValue } from '@chakra-ui/react';
import { SearchBox } from '@mapbox/search-js-react';


import styles from './styles/MainPage.module.css'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {formatDateRange} from './utils';
import { useTravel } from './TravelContext';

import FirstTimeVisit from './FirstTimeVisit';

import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import LoginAlert from './LoginAlert';
import NavBar from './Navbar';
import FooterSection from './landing/FoooterSection'
import axios from 'axios';


function findMatchingLocation(retrievedLocation, suggestions) {
  const retrievedNames = [
    retrievedLocation.place?.name,
    retrievedLocation.region?.name,
    retrievedLocation.district?.name
  ].filter(Boolean);

  const retrievedCountryId = retrievedLocation.country?.id;

  const matchedSuggestion = suggestions.find(suggestion => {
    const suggestionCountryId = suggestion.context?.country?.id;
    return (
      retrievedNames.some(retrievedName =>
        suggestion.name.toLowerCase() === retrievedName.toLowerCase() ||
        suggestion.place_formatted.toLowerCase().includes(retrievedName.toLowerCase())
      ) &&
      (suggestionCountryId === retrievedCountryId || !retrievedCountryId)
    );
  });


  return matchedSuggestion;
}


const { allowedMaxDays, beforeToday, combine } =
  DateRangePicker;

const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'BRL', label: 'BRL' },
  { value: 'INR', label: 'INR' },
  { value: 'AUD', label: 'AUD' },
  { value: 'CAD', label: 'CAD' },
];


const budgetOptions = [
  { value: 'Less than 500', label: 'Less than 500' },
  { value: '500-1000', label: '500-1000' },
  { value: '1000-2000', label: '1000-2000' },
  { value: '2000-5000', label: '2000-5000' },
  { value: '5000-10000', label: '5000-10,000' },
];

const NewTripPage = () => {
  
  const history = useNavigate(); // Get the history object from React Router
  const isMobileWidth = useBreakpointValue({ base: "95vw", md: "70vw" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  let effectRan = useRef(false);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [destination, setDestination] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [currency, setCurrency] = useState("EUR");
  const [budget, setBudget] = useState("500-1000");
  const [activities, setActivities] = useState([]);
  const [geoValue, setGeoValue] = React.useState('');
  const [days,setDays] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [loading,setLoading] = useState(false);
  const [countryCode,setCountryCode] = useState('us');
  const [baseCoordinates,setBaseCoordinates] = useState([]);
  let [additionalInfo, setAdditionalInfo] = useState("")
  const { updateTravelData } = useTravel();
  const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [ profile, setProfile ] = useState(JSON.parse(localStorage.getItem('profile')) || null);

  
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleDateRangeChange = (newDateRange) => {

    if (newDateRange && newDateRange.length === 2 && newDateRange[0] && newDateRange[1]) {
      const startDate = newDateRange[0];
      const endDate = newDateRange[1];
  
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      let daysBetween = Math.round(Math.abs((startDate - endDate) / oneDay));
      daysBetween += 1
  
      setDays(daysBetween);

      const formattedDateRange = formatDateRange(newDateRange);
  
      // Set the new dateRange values
      setDateRange(formattedDateRange);
    } else {
      console.log('Invalid date range');
    }
  };

  const handleGenerateItinerary = async () => {
    
    try {
      const unfilledFields = [];
      if (!destination) {
        unfilledFields.push('Destination');
      }
      if (dateRange[0] == null || dateRange[1] == null) {
        unfilledFields.push('Date Range');
      }
      if (!currency) {
        unfilledFields.push('Currency');
      }
      if (!budget) {
        unfilledFields.push('Budget');
      }

      if (unfilledFields.length > 0) {
        setErrorMessage(`Please fill in the following fields:\n ${unfilledFields.join('\n ')}`);
        setErrorDialogOpen(true);
        return;
      }

      const finalBudget = budget + currency
      if (additionalInfo == '') {
        additionalInfo = "None"
      }

      updateTravelData({
        destination: destination,
        days: days,
        dateRange: dateRange,
        currency: currency,
        budget: finalBudget,
        geoValue: geoValue,
        countryCode: countryCode,
        baseCoordinates: baseCoordinates
      });

      if (!profile) {
        onOpen();
        return;
      }


      history('/activities');


    } catch (error) {
      console.error('Error generating itinerary:', error);
    } finally {
      setLoading(false);
    }
  };



  const login = useGoogleLogin({
    
    onSuccess: (codeResponse) => {
      fetch(`${process.env.REACT_APP_AUTH_URL}/api/auth/google`, {
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: codeResponse.code }),
      })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('user', JSON.stringify(data));
        effectRan.current = false
        onClose();
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${JSON.parse(localStorage.getItem('user')).access_token}`, {
          method: 'GET',
          headers: new Headers({
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
            'Accept': 'application/json'
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
    
          return response.json();
        })
        .then(data => {
          console.log(`data is ${JSON.stringify(data)}`)
    
          setProfile(data);
          localStorage.setItem('profile', JSON.stringify(data));
          // getAccessToken()
          console.log(`access token: ` + JSON.parse(localStorage.getItem('user')).access_token)
    
          fetch(`${process.env.REACT_APP_AUTH_URL}/api/dbsignin`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(data),
          }).then(response => {
            console.log(response)
           
          })
    
          
        })
        .catch(error => console.log(error));
      })
      .catch(error => {
        console.error('Error:', error);
      });
    },
    accessType: 'offline',
    flow: 'auth-code',
    onError: (error) => console.log('Login Failed:', error)
  });

const logOut = () => {
  googleLogout();
  setUser(null);
  setProfile(null);
  fetch(`${process.env.REACT_APP_AUTH_URL}/api/logout`, {
    method: 'POST',
    credentials: 'include', // Include cookies with the request
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => response.json())
  .then(data => {
    console.log('Backend response:', data);
  })
  .catch(error => {
    console.error('Error:', error);
  });
  localStorage.removeItem('user');
  localStorage.removeItem('profile');
  localStorage.removeItem('jwt');
  
};


  
  return (
    <div>
      <NavBar profile={profile} logOut={logOut} onOpen={onOpen}></NavBar>
      <Container centerContent>
      <Heading as="h1" mt="2vw" mb="4vw" fontSize={{ base: "xl", md: "3xl" }}>Plan your next adventure</Heading>
      </Container>
      <Container centerContent>

      </Container>
      {/* Destination */}
      <Container>
      <div className={styles.inputGroup}>
      <FormLabel fontWeight="semibold" id='fonts' mb="1vw">Where do you want to go?</FormLabel>
      <form>
      
      <div className='search-box-input' style={{marginBottom: "0vh"}}>
      <SearchBox on
  value={destination} options={{
    language: 'en',
    types: 'place'
    }}
  placeholder='Select a city'
  accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
  onSuggest={(data) => {
    console.log(data.suggestions)
    setSuggestions(data.suggestions)
  }}
  onRetrieve={(data) => {
    console.log(data.features[0].properties.context)

    const foundCoordinates = data.features[0].properties.coordinates;
      let baseCoords;

      if (foundCoordinates.latitude) {
        baseCoords = [foundCoordinates.latitude, foundCoordinates.longitude]
      } else {
        baseCoords =  [foundCoordinates[0], foundCoordinates[1]]
      }

      console.log(`Base: ${baseCoords}`)
      setBaseCoordinates(baseCoords);

      let match = findMatchingLocation(data.features[0].properties.context,suggestions);
    data.features[0].properties.context.place.name = match.name
    let final_string = match.name
 

    if (
      data.features[0].properties.context.region &&
      data.features[0].properties.context.region.name &&
      data.features[0].properties.context.place.name !== data.features[0].properties.context.region.name
    ) {
      final_string += ', ' + data.features[0].properties.context.region.name;
    }

    if (data.features[0].properties.context.country && data.features[0].properties.context.country.name) {
      final_string += ', ' + data.features[0].properties.context.country.name;
    } else if (match.context.country && match.context.country.name) {
      final_string += ', ' + match.context.country.name;
    }

    setGeoValue(data.features[0].properties.context.place.name);
    console.log(`geoValue: ${data.features[0].properties.context.place.name}`)
    let code;

    if (data.features[0].properties.context.country) {
      code = data.features[0].properties.context.country.country_code.toLowerCase()
    } else if (match.context.country) {
      code = match.context.country.country_code.toLowerCase()
    }
    
    else {
      code = 'us'
    }

    console.log(`${code}`)

    setCountryCode(code);
    setDestination(final_string);
  }}
/>
      </div>

</form>
      </div>
      

      {/* Date Range */}
      <div className={styles.inputGroup}>
        {/* <label>Date Range:</label> */}
        <DateRangePicker shouldDisableDate={combine(allowedMaxDays(7), beforeToday())} ranges={[]} placeholder="Select Dates" showOneCalendar={isMobile} onChange={handleDateRangeChange} style={{
    
    padding: '10px 0px',
    marginBottom: "3vh",
    width: isMobileWidth
   
  }}  />
      </div>
      <FirstTimeVisit></FirstTimeVisit>
      <Divider className='customgray' borderWidth="0.06vw" marginBottom="6vh"/>


      {/* Currency */}
      <Box className={styles.inputGroup} mb={{base: "6vw",md:"3vw"}}>
      <FormLabel id='fonts' mb="3vh">What is your primary currency? <Text as='span' fontSize={{"base":"0.9rem","md":"1rem"}} style={{fontWeight: "normal"}} id='optional' ml="0.3vw"> (Optional)</Text></FormLabel>
    <Select 
      placeholder="Select your currency"
      options={currencyOptions}
      onChange={(selectedOption) => setCurrency(selectedOption.value)}
      chakraStyles={{
        control: (provided, state) => ({
          ...provided,
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: "white", // This sets the color of the dropdown indicator (the arrow)
          backgroundColor: "black"
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "black", // This sets the color of the selected item shown in the control
        }),
        multiValue: (provided, state) => ({
          ...provided,
          backgroundColor: "gray.800",
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          color: "white",
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          color: "white",
          ':hover': {
            backgroundColor: "gray.700",
            color: "white",
          },
        }),
      }}
    />
  
      </Box>

      {/* Budget */}
      <div className={styles.inputGroup}>
      <FormLabel id='fonts'>How much do you plan to spend on this trip? <Text as='span' fontSize={{"base":"0.9rem","md":"1rem"}} style={{fontWeight: "normal"}} id='optional' ml="0.3vw">(Optional)</Text>
      &nbsp;
      <HStack mt="0.1vw" mb="2vw"><Text as='span' fontSize={{"base":"0.9rem","md":"1rem"}}  style={{fontWeight: "normal"}} id='optional'>Consider</Text><Text fontSize={{"base":"0.9rem","md":"1rem"}} fontWeight="normal"  as='span' style={{lineHeight: "1.5rem"}}> Stay + Activities + Food.</Text> <Text as='span' fontSize={{"base":"0.9rem","md":"1rem"}}  style={{fontWeight: "normal"}} id='optional'>Do not include flights.</Text></HStack>
      </FormLabel>
        <Select placeholder="Select your budget"
          options={budgetOptions}
          onChange={(selectedOption) => setBudget(selectedOption.value)}
      chakraStyles={{
        control: (provided, state) => ({
          ...provided,

        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: "white", // This sets the color of the dropdown indicator (the arrow)
          backgroundColor: "black"
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "black", // This sets the color of the selected item shown in the control
        }),
        multiValue: (provided, state) => ({
          ...provided,
          backgroundColor: "gray.800",
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          color: "white",
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          color: "white",
          ':hover': {
            backgroundColor: "gray.700",
            color: "white",
          },
        }),
      }}
        />
      </div>
      </Container>

      <Container centerContent>
      <Button
      id='shadow'
            style={{ marginBottom: '2vw', marginTop: '1vw' }}
            color='white'
            borderRadius="9999px"
            backgroundColor="black"
            _hover={{color: "white",backgroundColor: "black"}}
            fontWeight="500"
            onClick={handleGenerateItinerary}
          >
            Create New Trip
          </Button>
      </Container>
      <LoginAlert onClose={onClose} isOpen={isOpen} login={login}></LoginAlert>


      <AlertDialog
        isOpen={errorDialogOpen}
        onClose={handleCloseErrorDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
                maxW={{ base: '90%', md: 'md' }} // Adjust the width based on your preference
                w={{ base: '100%', md: 'auto' }}

          
          >
            <AlertDialogHeader fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
             <Container centerContent>
             
             <Text fontSize={{ base: '1rem', md: '1.2rem', }} id='fonts'>Oops, You still have unfilled fields!</Text>
             </Container>
            </AlertDialogHeader>

            <AlertDialogBody>
  {errorMessage && (
    <Container centerContent>
      <ul>
      {errorMessage.split('\n').map((message, index) => (
        <li key={index} style={{ listStyle: index === 0 ? 'none' : 'initial'}}>
          {index === 0 ? (
            <VisuallyHidden>{message}</VisuallyHidden>
          ) : (
            <Text>{message}</Text>
          )}
        </li>
      ))}
    </ul>
    </Container>
  )}
</AlertDialogBody>



            <AlertDialogFooter>
             <Container centerContent>
             <Button color='white'
             borderRadius="9999px"
            backgroundColor="black"
            _hover={{color: "white",backgroundColor: "black"}}
            fontWeight="500" onClick={handleCloseErrorDialog} ml={3}>
                Return
              </Button>
             </Container>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
            <FooterSection></FooterSection>
      </div>
  );
};


export default NewTripPage;
