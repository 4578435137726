import {
    Box,
    Text,
    Container,
    Button,
    Image,
  } from '@chakra-ui/react';
  import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'rsuite';
import bestplace from './bestplace.png'


  const CallToAction = () => { 
    const navigate = useNavigate(); // Correct usage
  
    // Define the handleNavigate function to use the navigate function
    const handleNavigate = () => {
      navigate('/new-trip'); // Use the navigate function for navigation.
    };
    return (
        <>
                <Box fontSize="1.875rem" textAlign="center" mb={{base:"5vh",md:"2vh"}} lineHeight="2.25rem">
    <Text as="span" color="black">Personalised </Text>
    <Text as="span" color="#C8A0FF" fontWeight="600">Itineraries </Text>
    <Text as="span" color="black">for every travel fantasy</Text>
</Box>
<Container textAlign="center" maxW={{base: "100vw", md:"40vw"}} mb={10}>
    {/* <Divider></Divider> */}
    <Container pl="3.5rem" display={{base:"none",md:"block"}}>
    <Image alt='ai trip planner travel planner ai' w="500" h="300" src={bestplace}></Image>

    </Container>

<Text fontSize={{ base: "1.125rem", md: "1.125rem" }} lineHeight="1.75rem" className='tshad' color="#9e9e9e">Pathpal is your ultimate buddy for any trip you desire. Whether it's a solo adventure, a family vacation, or a group journey, our tool personalises every aspect of your adventure.

</Text>
<Button
    mt="2rem"
    w={{base: "100%", md:"16vh"}} // Adjusted for alignment, "initial" uses the button's natural width
    backgroundColor="black"
    color="white"
    borderRadius="9999px"
    variant="solid"
    h="5vh"
    _hover={{
      backgroundColor: "black",
      color: "white"
    }}
    size="md"
    mb="10"
    onClick={handleNavigate}
  >
    Start Planning
  </Button>

</Container>
        </>
    )
  }

  export default CallToAction 