import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './Navbar';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import LoginAlert from './LoginAlert';
import { Box, Button, Image, Text, Container, Flex, VStack, HStack, Avatar, useColorModeValue,useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    RadioGroup,
    Stack,
    Radio,
    Center,
    ModalCloseButton,
    ChakraProvider,
   } from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';
import { extendTheme } from '@chakra-ui/react';
import axios from 'axios';
import FooterSection from './landing/FoooterSection'


const theme = extendTheme({
  components: {
    Radio: {
      baseStyle: (props) => ({
        control: {
          _checked: {
            bg: 'black',
            borderColor: 'black',
            color: 'white',

            _hover: {
              bg: 'black',
              borderColor: 'black',
            },
            _visited: {
                bg: 'black',
            },
            _focus: {
                boxShadow: 'none',
            },
            
            
          },
          borderColor: 'rgb(224,224,224)',
          background: "#f5f5f5"
        },
        label: {
          color: 'black',
        },
      }),
    },
  },
});



const Settings = () => {
    const history = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modalDisclusre = useDisclosure()
    const [reason, setReason] = useState('1'); // State to hold the selected reason

    const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user')) || null);
    const [ profile, setProfile ] = useState(JSON.parse(localStorage.getItem('profile')) || null);

    const deleteUser = async () => {
        const userId = JSON.parse(localStorage.getItem("profile")).id;
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/deleteUser`, { userId });
          console.log(response.data.message);
          modalDisclusre.onClose()
          history("/");
        } catch (error) {
          console.error("Deletion failed:", error.response ? error.response.data.message : error.message);
        }
      };

    const login = useGoogleLogin({
    
        onSuccess: (codeResponse) => {
          fetch(`${process.env.REACT_APP_AUTH_URL}/api/auth/google`, {
            method: 'POST',
            credentials: 'include',
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: codeResponse.code }),
          })
          .then(response => response.json())
          .then(data => {
            console.log('Backend response:', data);
            localStorage.setItem('user', JSON.stringify(data));
            // effectRan.current = false
            onClose();
            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${JSON.parse(localStorage.getItem('user')).access_token}`, {
              method: 'GET',
              headers: new Headers({
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
                'Accept': 'application/json'
              })
            })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
        
        
              return response.json();
            })
            .then(data => {
              console.log(`data is ${JSON.stringify(data)}`)
        
              setProfile(data);
              localStorage.setItem('profile', JSON.stringify(data));
              // getAccessToken()
              console.log(`access token: ` + JSON.parse(localStorage.getItem('user')).access_token)
        
              fetch(`${process.env.REACT_APP_AUTH_URL}/api/dbsignin`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                },
                body: JSON.stringify(data),
              }).then(response => {
                console.log(response)
               
              })
        
              
            })
            .catch(error => console.log(error));
          })
          .catch(error => {
            console.error('Error:', error);
          });
    
        },
        accessType: 'offline',
        flow: 'auth-code',
        onError: (error) => console.log('Login Failed:', error)
      });
    
      const logOut = () => {
        googleLogout();
        setUser(null);
        setProfile(null);
        fetch(`${process.env.REACT_APP_AUTH_URL}/api/logout`, {
          method: 'POST',
          credentials: 'include', // Include cookies with the request
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => response.json())
        .then(data => {
          console.log('Backend response:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
        localStorage.removeItem('user');
        localStorage.removeItem('profile');
        localStorage.removeItem('jwt');
        history("/")
        
      };

    if(!profile) {
        history("/")
    }
    return (
        <>
 <ChakraProvider theme={theme}>
 <NavBar profile={profile} logOut={logOut} onOpen={onOpen}></NavBar>
        <LoginAlert onClose={onClose} isOpen={isOpen} login={login}></LoginAlert>
        <Container maxW="70vw">
  <Box px={{ base: 4, md: 12 }} pt={12} mx="auto">
    <Text fontSize="1.5rem" lineHeight="2rem" className='tshad'>Profile Settings</Text>
    <Text color="#757575" fontSize="1rem" textShadow="0 0 0 #757575" mt={0}>Edit your traveler information</Text>
    <Box mt={12} pt={7} borderTop="1px" borderColor="rgb(224,224,224)">
      <Flex direction={{ base: "column", md: "row" }} justify="space-between" align="center">
        <Flex align="center" flexGrow={1}>
          <Avatar size="md" name="Vagif Aliyev" src="https://lh3.googleusercontent.com/a/ACg8ocJrD3qNYRT-efW-gCSLN52NH6rhO2ibjzggR7rdzxD3ZeQ=s96-c" />
          <Box ml={4}>
            <Text color="black" fontSize="lg" className='tshad'>{profile.name}</Text>
            <Text fontSize="1rem" lineHeight="1.5rem" textShadow="0 0 0 #bdbdbd" color="#bdbdbd">{profile.email}</Text>
          </Box>
        </Flex>
        <Button onClick={() => {
           modalDisclusre.onOpen()
        }} leftIcon={<DeleteIcon />} color="white" mt={{base:6, md:0}} backgroundColor="rgb(230,111,125)" variant="solid" _hover={{
            "backgroundColor": "rgb(232 77 96)"
          }}>
          Delete account
        </Button>
      </Flex>
    </Box>
  </Box>
</Container>
<Modal  isOpen={modalDisclusre.isOpen} onClose={modalDisclusre.onClose} isCentered>
        <ModalOverlay backdropFilter='blur(10px)'/>
        <ModalContent>
          <ModalHeader>
            <Text textAlign="center" fontWeight="600">Confirm Account Deletion</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container centerContent px={2}>
            <Text color="black" fontSize="1rem">Please note that deleting your profile is irreversible.</Text>
            <Text color="black" fontSize="1rem" mt={2}>Before you go, can you share why you're leaving?</Text>
            <RadioGroup onChange={setReason} value={reason} mt={4}>
  <Stack direction="column">
    <Radio value="1" 
    sx={{ 
        '.chakra-radio__control': { 
          borderColor: 'black', 
          _checked: { 
            bg: 'black', 
            borderColor: 'black',
            color: 'black',
          },
          _hover: {
            borderColor: 'gray.600',
          }
        },
        '.chakra-radio__label': {
          color: 'black',
        },
      }}
    
    ><Text color="#616161">I'm not going to use it anymore.</Text></Radio>
    <Radio
    sx={{ 
        '.chakra-radio__control': { 
          borderColor: 'black', 
          _checked: { 
            bg: 'black', 
            borderColor: 'black',
            color: 'black',
          },
          _hover: {
            borderColor: 'gray.600',
          }
        },
        '.chakra-radio__label': {
          color: 'black',
        },
      }}
     value="2" ><Text color="#616161">It didn't meet my expectations.</Text></Radio>
    <Radio
     value="3" ><Text color="#616161">I found a better alternative.</Text></Radio>
  </Stack>
</RadioGroup>
            </Container>

          </ModalBody>
          <ModalFooter >
            <Container centerContent>
            <Button  color="white" backgroundColor="rgb(230,111,125)" variant="solid" _hover={{
            "backgroundColor": "rgb(232 77 96)"
          }} mr={3} onClick={deleteUser}>
              Delete account
            </Button>
            </Container>
          </ModalFooter>
        </ModalContent>
      </Modal>
 </ChakraProvider>
 <FooterSection></FooterSection>
        </>
    )
}

export default Settings