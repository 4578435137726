import React, { createContext, useContext, useState } from 'react';

const TravelContext = createContext();

export const useTravel = () => useContext(TravelContext);

export const TravelProvider = ({ children }) => {
  const initialState = {
    destination: '',
    days: 0,
    dateRange: [null, null],
    currency: '',
    budget: '',
    activities: [],
    customActivities: '',
    activitiesPerDay: '',
    geoValue: '',
    countryCode: '',
    hiddenGemLevel: '',
    additionalInfo: '',
    baseCoordinates: ''
  };


  const [travelData, setTravelData] = useState(initialState);

  const updateTravelData = (data) => {
    setTravelData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const resetTravelData = () => {
    setTravelData(initialState);
  };

  return (
    <TravelContext.Provider value={{ travelData, updateTravelData,resetTravelData }}>
      {children}
    </TravelContext.Provider>
  );
};