import {
    Box,
    Button,
    Container,
    Flex,
    Img,
    Spacer,
    Text,
    useMediaQuery,
  } from '@chakra-ui/react';
  import React from 'react';
  import chakraHero from './travel_booking.png';
import { useNavigate } from 'react-router-dom';
  
  
const Hero = () => {
    const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
    // Directly get the navigate function from useNavigate hook.
    const navigate = useNavigate(); // Correct usage
  
    // Define the handleNavigate function to use the navigate function
    const handleNavigate = () => {
      navigate('/new-trip'); // Use the navigate function for navigation.
    };
  
    return (
  <Flex
    alignItems="center"
    w="full"
    px="2vw"
    mt={10}
    minHeight={0}
    justifyContent="space-between"
    flexDirection={isLargerThanLG ? 'row' : 'column'}
  >
    <Box w={isLargerThanLG ? '50%' : 'full'}>
      <Container mb={{base:"0vh",md:"13vh"}}>
        <Text
          fontSize={isLargerThanLG ? '5xl' : '4xl'}
          fontWeight="bold"
          mb="0px"
          mt={{base:"2vh",md:"0vh"}}
        >
          PathPal
        </Text>
        <Text className='tshad'
          color="#C8A0FF"
          fontSize={{md: "2.25rem", base:"1.875rem"}}
          fontWeight="normal"
          mb="4"
        >
          
          Embark on Tailored Journeys
        </Text>

        <Text mb="6" color="#9e9e9e" className='tshad' fontWeight="500" lineHeight="1.75rem" fontSize={{base: "1rem", md: "1.25rem"}}>
        Craft, refine, and elevate your travel experiences with our intuitive AI trip planner. Seamlessly curated for vacations, workations, and moments of exploration.
        </Text>
        <Button
    w={{base: "100%", md:"16vh"}} // Adjusted for alignment, "initial" uses the button's natural width
    backgroundColor="#00501E"
    color="white"
    variant="solid"
    h="6vh"
    _hover={{
      backgroundColor: "#00501E",
      color: "white"
    }}
    size={isLargerThanLG ? 'lg' : 'md'}
    mb={isLargerThanLG ? '0' : '10'}
    onClick={handleNavigate}
  >
    New Trip
  </Button>
      </Container>

    
    </Box>
    <Spacer display={isLargerThanLG ? 'none' : 'flex'} />
    <Flex
      w={isLargerThanLG ? '50%' : 'full'}
      alignItems="center"
      justifyContent="center"
    >
      <Img src={chakraHero} alt="Pathpal: AI Trip planner"  display={{base:"none",md:"block"}}  />
    </Flex>
  </Flex>
);
  };
  
  export default Hero;
  