import React, { useState } from 'react';
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useBreakpointValue,
  ModalCloseButton,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
  Center,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';


function CopyComponent({ onClose, isOpen,handleCopy }) {
  const duplicateButtonText = useState("Copy Itinerary");

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center>
            Clone this itinerary
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4} fontSize="1rem">
            To edit this itinerary, make a clone and personalise it to suit your needs!
            </Text>
            <InputGroup size="md">
            <Button color="white" bg="#C8A0FF" size="md" _hover={{ color: "white", backgroundColor: "#C8A0FF" }} onClick={handleCopy}>
            <FontAwesomeIcon size='15' icon={faCopy}  color={'white'} />

                  <Text ml={2}>{duplicateButtonText}</Text>
                </Button>
            </InputGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CopyComponent;