const formatDateRange = (dateRange) => {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = new Date(dateRange[0]);
      const endDate = new Date(dateRange[1]);
  
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const formattedStartDate = startDate.toLocaleDateString('en-US', options);
      const formattedEndDate = endDate.toLocaleDateString('en-US', options);
  
      return `${formattedStartDate} to ${formattedEndDate}`;
    }
  
    return '';
  };

const formatActivitiesList = (activities) => {
    return activities.join(', ');
  };

const removeLinkKeys = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        removeLinkKeys(obj[key]);
      } else if (key === 'link' || key == "position") {
        delete obj[key];
      }
    }
  }




export { formatDateRange, formatActivitiesList,removeLinkKeys };
