import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import { ChakraProvider } from '@chakra-ui/react';
import NewTripPage from './newTripPage';
import ItineraryPage from './ItineraryPage';
import reportWebVitals from './reportWebVitals';
import { PrimeReactProvider } from 'primereact/api';
import { TravelProvider } from './TravelContext';
import ActivitiesPage from './ActivitiesPage';
import HiddenGems from './HiddenGems';
import LandingPage from './LandingPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ItineraryList from './ItineraryList';
import Settings from './Settings';
import Terms from './landing/Terms';
import Policy from './landing/Policy';
import ProtectedRoute from './ProtectedRoute'




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId="1033566010989-g67pj2tmfg6kart8advv42agp8pv3cae.apps.googleusercontent.com">
  <React.StrictMode>
    <PrimeReactProvider>
    <ChakraProvider>
    <TravelProvider>
      <div>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/new-trip" element={<NewTripPage/>} />
          <Route path="/activities" element={<ActivitiesPage/>} />
          <Route path="/itinerary/:id" element={<ItineraryPage/>} />
          <Route path="/gems" element={<HiddenGems/>} />
          <Route path="/itinerary" element={
           <ProtectedRoute>
           <ItineraryList />
         </ProtectedRoute>
          } />
          <Route path='/settings' element={
          <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
          } />
          <Route path='/terms' element={<Terms/>} />
          <Route path='/privatepolicy' element={<Policy/>} />
        </Routes>
      </Router>
      </div>
      </TravelProvider>
    </ChakraProvider>
    </PrimeReactProvider>
  </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
