import Hero from './landing/Hero';
import { useDisclosure, Box } from '@chakra-ui/react';
import KeyBenefits from './landing/KeyBenefits';
import Why from './landing/Why';
import Partners from './landing/Partners';
import CallToAction from './landing/CallToAction';
import NavBar from './Navbar';

import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import LoginAlert from './LoginAlert';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FooterSection from './landing/FoooterSection'



function LandingPage() {
  const history = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [ profile, setProfile ] = useState(JSON.parse(localStorage.getItem('profile')) || null);
    if (JSON.parse(localStorage.getItem('profile'))) {
      history('/itinerary')
    } 
   


  const login = useGoogleLogin({
    
    onSuccess: (codeResponse) => {
      fetch(`${process.env.REACT_APP_AUTH_URL}/api/auth/google`, {
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: codeResponse.code }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Backend response:', data);
        localStorage.setItem('user', JSON.stringify(data));
        // effectRan.current = false
        onClose();
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${JSON.parse(localStorage.getItem('user')).access_token}`, {
          method: 'GET',
          headers: new Headers({
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
            'Accept': 'application/json'
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
    
          return response.json();
        })
        .then(data => {
          console.log(`data is ${JSON.stringify(data)}`)
    
          setProfile(data);
          localStorage.setItem('profile', JSON.stringify(data));
          // getAccessToken()
          console.log(`access token: ` + JSON.parse(localStorage.getItem('user')).access_token)
    
          fetch(`${process.env.REACT_APP_AUTH_URL}/api/dbsignin`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(data),
          }).then(response => {
            console.log(response)
            history("/itinerary")
           
          })
    
          
        })
        .catch(error => console.log(error));
      })
      .catch(error => {
        console.error('Error:', error);
      });
    },
    accessType: 'offline',
    flow: 'auth-code',
    onError: (error) => console.log('Login Failed:', error)
  });

  const logOut = () => {
    googleLogout();
    setUser(null);
    setProfile(null);
    fetch(`${process.env.REACT_APP_AUTH_URL}/api/logout`, {
      method: 'POST',
      credentials: 'include', // Include cookies with the request
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log('Backend response:', data);
      // Proceed with any additional logout operations, such as clearing frontend state
    })
    .catch(error => {
      console.error('Error:', error);
    });
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('jwt');
    
  };
    return (
      <>
      <NavBar profile={profile} logOut={logOut} onOpen={onOpen} />
      <Box pt={0}>
        <Hero />
        <LoginAlert onClose={onClose} isOpen={isOpen} login={login}></LoginAlert>
       <Box mt="5vh">
       <KeyBenefits/>
       </Box>
       <Box mt={{base: "10vh",md:"0"}}>
        <Why/>
       </Box>
       <Box mt={{base: "10vh",md:"15vh"}}>
        <Partners/>
       </Box>
       <Box mt={{base: "10vh",md:"15vh"}}>
        <CallToAction/>
       </Box>
      </Box>
      <FooterSection></FooterSection>

      
      </>
    );
  }
  
  export default LandingPage;