import React from 'react';
import { Box, Heading, Text, Button, Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import FooterSection from './FoooterSection'


function Policy() {
  const navigate = useNavigate();

  return (
    <>

<Box>
      <Box bg="#C8A0FF" p={5}>
        <Heading color="white" textAlign="center">Private Policy</Heading>
      </Box>

      <Box p={5}>
        <Text fontSize="md" as='p' mt={4}>
        PathPal AI, domiciled at Jannicksweg 122, 7513DN, Enschede, The Netherlands (the “Website”), takes the users (“Users”) privacy very seriously. The purpose of this “Privacy Policy” is to explain Users how PathPal AI may collect, retain, process, share and transfer Users personal information in order to provide its Services through the website: https://pathpal.app.
<br></br>
<br></br>
By providing PathPal AI with their personal data through the Website, Users expressly consent to the fact that PathPal AI may process such data in accordance with the terms of this Privacy Policy and for the purposes set forth herein.
<br></br>
<br></br>
Users must read this Privacy Policy before registering or using the Services on the Website. By clicking on the “register” button, Users state that they have read this Privacy Policy and expressly agree to it. PathPal AI will not collect Personal Information from Users unless it is voluntarily provided by such Users.
<br></br>
<br></br>
PathPal AI reserves the right, at its discretion, to modify this Privacy Policy from time to time to reflect changes in its business, services, or applicable laws. To this end, PathPal AI will provide Users with thirty (30) days' prior notice of any modifications on the Privacy Policy page of PathPal AI’s Website. PathPal AI may also notify Users of modifications using the contact information, email, or other means of contact provided by Users. The revised Privacy Policy will take effect on the date of publication. Users may contact PathPal AI if they have questions about PathPal AI’s privacy practices that are not addressed in this Privacy Policy.
<br></br>
<br></br>
Users agree to provide true and accurate data. Furthermore, they shall be solely responsible for any loss or damage that may be suffered by PathPal AI, any of its subsidiaries, or third parties as a result of the information provided being false, inaccurate, out of date, or not genuine.
<br></br>
<br></br>
PathPal AI respects and is constantly striving to comply with the European General Data Protection Regulation 2016/679 (“GDPR”) to protect, warrant, and preserve the rights of Users who may eventually be subject to or benefit from it.
        </Text>

        <Heading  fontWeight="800" size="md" mt={6}>1. Who is the Controller in relation to the Personal Information Provided by Users?</Heading>
        <Text fontSize="md" mt={2}>
        All Personal Information provided or collected through the Website will be processed by PathPal AI, whose contact information can be found below, as file controller.
        <br></br>

       <Text ml={10}>
       <ul>
       <br/>
        <li> <b>Identity</b>: Jannicksweg 122, 7513DN, Enschede, The Netherlands</li>
        <br/>
        <li> <b>Postal address</b>: PathPalAI, Inc</li>
       <br/>
       <li> <b>Telephone no.</b>: +(353) 87 706 1684</li>
        <br/>
       <li> <b>Contact Form</b>: teampathpal@gmail.com</li>
       <br/>

       </ul>
       </Text>

        </Text>

        <Heading fontWeight="800" size="md" mt={6}>2. About the Website and Services</Heading>
        <Text fontSize="md" mt={2}>
        PathPal AI simplifies the process of planning trips for Users (the “Service” or “Services”). The primary goal is to assist Users in generating optimized itineraries based on their preferences and desired destinations. PathPal AI does not deliver guide, tour, or direct tourist services; it merely offers a platform where Users can automate the creation and optimization of their travel plans.
    
        <br/>
        <br/>
        The User recognizes and agrees that any term not defined in this document may be defined in the Terms and Conditions of Service complementary to this Privacy Policy.
        </Text>

        <Heading fontWeight="800" size="md" mt={6}><b>A: What Information Does PathPal AI collect and how is it collected?</b></Heading>
        <Text fontSize="md" mt={2}>
        PathPal AI processes the personal information of Users to provide its Service (“Personal Information”).
        <br></br>
        <br></br>

Generally, Users’ Personal Information will include: email, first and last name, local ID, and its photo. Specifically, PathPal AI may collect the following information:
<br></br>
<Text ml={10}>
       <ul>
       <br/>
        <li><b>Registration and Use Information</b>: When Users engage with the Services by creating an account ("Account"), PathPal AI collects necessary Personal Information to offer its travel planning functionalities. PathPal AI may require Users to provide the following Personal Information: email, first and last name, local ID, and its photo. PathPal AI reserves the right to request additional Personal Information as needed.</li>
        <br/>
        <li><b>Trip Information</b>: When Users utilize the Service to optimize their travel itineraries, PathPal AI may require information about their upcoming trips. Specifically, PathPal AI may collect details on the destination, preferred currency, duration of the trip, start and end dates, ID, language preference, results of the generated itinerary, User's location, timezone, browser type, and initial referral source.</li>

       <br/>
        <li><b>Additional Information Provided by Users</b>: PathPal AI may collect any additional information that Users choose to share for other purposes. For instance, Users might submit a photograph or a video clip for potential publication on PathPal AI’s social media platforms.</li>
        <br/>
        <li><b>Communications with PathPal AI</b>: PathPal AI will have access to information provided by Users concerning inquiries or complaints about the use of the Website, when such communications are sent to teampathpal@gmail.com</li>
        <br/>
        <li><b>Non-Identifying Information</b>:PathPal AI may also collect other types of information, such as zip codes, demographic data, and details concerning the User's use of the Service, along with general data related to PathPal AI projects (“Non-Identifying Information”). PathPal AI may aggregate information gathered from both registered and non-registered users. In some instances, PathPal AI may transform Personal Data (typically, email addresses) into a form of non-identifying information within this Privacy Policy referred to as “Hashed Information.” This is done using a mathematical process (commonly known as a hash function) to convert information into a code, which does not directly identify Users but can be used to connect their activities and interests with the Website.</li>
       </ul>
       </Text>

        </Text>

        <Heading fontWeight="800" size="md" mt={6}>Information Received from Third Parties</Heading>
        <Text fontSize="md" mt={2}>
        Third parties may provide PathPal AI with information about the User. If PathPal AI combines such information with information collected through the Service or the Website, PathPal AI will process that combined information as described in this Privacy Policy.
        </Text>


        <Heading fontWeight="800" size="md" mt={6}>Information Collected Automatically</Heading>
        <Text fontSize="md" mt={2}>
        Like many online services, PathPal AI collects technical information when the User accesses or uses the Website or Services. Technologies are utilized to analyze user engagement with the Service, enhance the Website, save User login details for future visits, and to deliver targeted advertisements that might interest the User.

        <br></br>
        <br></br>
        PathPal AI, along with its service providers including analytics and third-party content providers, may automatically gather certain information whenever the User interacts with the Service or Website. This includes, but is not limited to, the type of browser and operating system being used, the search terms that led the User to the Service, areas of the Service visited, links clicked, pages or content viewed and the duration of those views, and other related interaction information, such as content response times, download errors, and visit lengths. This data is collected to continually improve PathPal AI’s Services and the overall functionality of the Website.
        <br></br>
        <br></br>
For these purposes, the Website may utilize cookies, tracking pixels, and similar technologies. Cookies are small data files placed on the User's device by the Website, enabling PathPal AI to recognize Users when they navigate the Website to deliver personalized advertisements tailored to Users.
        </Text>

        <Heading fontWeight="800" size="md" mt={6}><b>B: What Privacy Choices Are Available To Users ?</b></Heading>
        <Text fontSize="md" mt={2}>
        Users have several options concerning the privacy practices described in this Privacy Policy:
        <br></br>
        <br></br>

        Choices Relating to the Collection of Personal Information. Users may choose not to provide Personal Information. However, opting out of providing such information may render certain Services partially or completely inaccessible.
        <br></br>
        <br></br>
        Choices Relating to Cookies. Users have choices about managing their cookie preferences. Browsers or devices may allow the deletion, disabling, or blocking of cookies and other tracking technologies. Opting to block these technologies might hinder the performance and features of the Service, affecting user experience. Additionally, Users may be prompted to decide if they want the Website or Service to remember specific details about them through the use of cookies and related technologies, aligned with the permissions granted by the Users.
        <br></br>
        <br></br>
        Choices Relating to Registration and Account Information. If Users have an account registered via their Google email, they can review and alter their Personal Information directly through their Google account settings. PathPal AI does not influence changes or modifications to a User's Google account information.
        <br></br>
        <br></br>
        Choices Relating to Communication. Notices, Alerts and Updates from PathPal AI
        <br></br>
        <br></br>
        Marketing: PathPal AI may send marketing materials about its own services, the Website, and from third-party entities across various channels, including emails. Users have the option to opt-out of receiving these promotional communications by following the instructions provided within these messages.
        <br></br>
        <br></br>
        Informational and Other: PathPal AI will send communications that are necessary or required concerning the Services, as well as other requested communications, which include crucial updates and information. 



        </Text>


        <Heading fontWeight="800" size="md" mt={6}><b>C: For What Purpose is the Personal Information Collected?</b></Heading>
        <Text fontSize="md" mt={2}>  
        PathPal AI has no interest in retaining Personal Information about Users beyond what is necessary for the functionality and improvement of its Services. PathPal AI may retain Personal Information to fulfill its legal obligations and for its legitimate business purposes. PathPal AI may keep Personal Information for periods longer than a year and a half where justified by legitimate business interests.
        <br></br>
        <br></br>
In the event that PathPal AI needs to deactivate an Account, steps will be taken to anonymize Personal Information and other related information, though PathPal AI reserves the right to retain and access the data as required to comply with applicable laws. PathPal AI will continue to handle such Personal Information in accordance with this Privacy Policy.
<br></br>
<br></br>
PathPal AI collects, processes, and discloses Users' personal data solely for specific and limited purposes. For instance, to deliver its Services, address and resolve any complaints, further develop and enhance its Services, methods of communication, and the functionality of its Website, and to provide personalized services including communications and targeted advertisements to Users.
<br></br>
<br></br>
PathPal AI processes and utilizes Users' Personal Information for the following reasons:
<br></br>
<Text ml={10}>
       <ul>
       <br/>
        <li>To deliver the Services described on the Website.</li>
        <br/>
        <li>To provide Users with updates on their service status, handle their inquiries and requests, and manage and resolve any complaints.</li>
       <br/>
        <li>To process and respond to User inquiries or to contact them in response to their questions and/or requests.</li>
        <br/>
        <li>To enhance and improve the Service, communication methods, and the functionality of the Website</li>
        <br/>
        <li>To communicate information to Users and manage their registration and/or subscription to PathPal AI’s newsletter or other communications.</li>
        <br/>
        <li>To verify the identity of individuals contacting PathPal AI by telephone, electronic means, or otherwise.</li>
        <br/>
        <li>To understand and analyze the interests, desires, and evolving needs of Users, to enhance the Website, existing services, and/or develop new services.</li>
        <br/>
        <li>To provide personalized services and communications, and targeted advertising, as well as service recommendations to Users.</li>
        <br/>
        <li>For direct marketing purposes.</li>
        <br/>
        <li>To manage risks and safeguard the Website, the Service, and Users from fraud by verifying the identity of PathPal AI and Users, aiding in the detection and prevention of fraud and abuse of the Website or Services.</li>
        <br/>
        <li>To manage PathPal AI’s business needs, such as monitoring, analyzing, and enhancing the Services and the Website’s performance and functionality.</li>
        <br/>
        <li>To adhere to PathPal AI’s obligations and enforce the terms of its Website and Services, including compliance with all applicable laws and regulations. PathPal AI urges Users to adhere to all applicable data privacy regulations within all relevant jurisdictions.</li>
        <br/>

       </ul>
       </Text>
       In the event of a change in ownership of PathPal AI or if a majority of its assets are acquired by a third party, Users will be informed that their data will be transferred to the acquiring entity in order to continue providing Service under the data processing agreement. The new data controller will notify Users of its identity. PathPal AI commits to fulfilling its obligation to inform the appropriate regulatory authority under such circumstances and will notify Users about the change of data controller.
       <br></br>
       <br></br>
       PathPal AI may share User personal data with associated third parties, only if Users have provided their explicit, informed, and unambiguous consent, being fully aware of the purpose and recipient of such transfer. 


        
        
                </Text>

    
    
                <Heading fontWeight="800" size="md" mt={6}><b>D: Profiling</b></Heading>
        <Text fontSize="md" mt={2}>   
        PathPal AI utilizes Users' Personal Information to construct profiles. PathPal AI develops these profiles by analyzing information about Users’ preferences, travel intents, and interactions with PathPal AI brand communications by creating segments (forming groups that share certain common attributes) and classifying Users' personal data into one or more of these segments. These segments help PathPal AI to tailor the Website and PathPal AI communications to Users (for example, by displaying relevant content to them when they use the Service or through targeted communications like newsletters), and to present appropriate offers and advertisements on the Website. PathPal AI undertakes the profiling of Personal Information where Users have consented to this activity (for instance, by agreeing to the Privacy Policy, consenting to the use of cookies in their online browser, or subscribing to PathPal AI email newsletters).
        <br></br>
        <br></br>
Users have the right to withdraw their consent at any time to prevent their Personal Information from being used in this manner by opting out of the use of their email address if they have signed up for any marketing newsletters.
       
        
                </Text>


                <Heading fontWeight="800" size="md" mt={6}><b>E: Do We Share/Transfer Personal Information?</b></Heading>
        <Text fontSize="md" mt={2}> 
        Only for the purposes stated in this Privacy Policy and to perform the Service, Personal Information may be disclosed by PathPal AI, when necessary, to authorities, other companies within the same PathPal AI affiliates, PathPal AI’s partners, and to other third parties, such as third-party service providers used in connection with PathPal AI's Service.
        <br/>
        <br/>
        PathPal AI may also share non-identifying information with third parties. Users have choices as to whether PathPal AI shares their personal information with third parties.
        <br/>
        <br/>
        PathPal AI may share aggregated non-identifying information and may otherwise disclose non-identifying information (including, without limitation, Hashed Information) to third parties. We do not share Users' Personal Data with third parties for those third parties’ marketing purposes unless PathPal AI first provide the Users with the opportunity to opt-in to or opt-out of such sharing.
        <br/>
        <br/>
        PathPal AI may also share the information we have collected about Users, including Personal Data, as disclosed at the time the Users provide their Personal Data, with their consent, as otherwise described in this Privacy Policy, or in the following circumstances:
        
        <Text ml={10}>
       <ul>
       <br/>
        <li><b>Service Providers: </b> PathPal AI may employ third-party companies and individuals to facilitate the Service, to provide the Service on PathPal AI's behalf, to perform Service-related services (e.g., without limitation, maintenance services, database management, web analytics, payment processing, fraud detection and improvement of PathPal AI’s features) or to assist PathPal AI in analyzing how the Service is used. These third parties may have access to User Personal Data to perform these tasks on PathPal AI’s behalf</li>
        <br/>
        <li><b>Legal and Investigative Purposes: </b> PathPal AI will share information with government agencies as required by law in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. Furthermore, PathPal AI cooperates with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose information about Users to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), or, at the request of governmental authorities or other third parties conducting an investigation where PathPal AI determine in our sole discretion the disclosure is necessary to (a) protect the property and rights of PathPal AI or a third party, (b) protect the safety of the public or any person, or (c) prevent or stop any activities we consider to be, or to pose a risk of being, illegal, fraudulent, unethical or legally actionable activity.</li>
       <br/>
        <li><b>Internal and Business Transfers: </b> PathPal AI may share information, including Personal Data, with any current or future subsidiaries or affiliates, primarily for business and operational purposes, for them to provide services to the Users, or support and supplement the Services we provide. We may sell, transfer, or otherwise share some or all of PathPal AI’s assets, including Users' personal data, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy.</li>

       </ul>
       </Text>
       <br></br>
       Because PathPal AI is based in the U.S., we process and store Users' information in the Netherlands and our service providers may process and store it elsewhere. These countries may not have the same data protection laws as the country in which the Users initially provided the information. When PathPal AI transfers Users' information to the Netherlands, we will protect it as described in this Privacy Policy.
        <br></br>
        <br></br>
        The Service is managed by PathPal AI’s team at its headquarters in San Francisco, USA. PathPal AI relies on service providers to operate the Website and provide the Services. These service providers are all located in and process Users' data in the Netherlands. PathPal AI does not currently market its Services to individuals or businesses outside of the Netherlands.
        <br></br>
        <br></br>
        In some cases, Personal Information may be transferred outside the European Union and the European Economic Area (“EU/EEA”), including but not limited to, the Netherlands. PathPal AI will only send Personal Information collected within the EEA to foreign countries to follow Users instructions, to comply with a legal duty, or to work with Users or other travel service providers who propose tours on the Website.
        <br></br>
        <br></br>

        In such cases, such transfers outside the EEA will be performed subject to appropriate safeguards and safety measures such as standard data protection clauses adopted in respective agreements or otherwise approved by the EU Commission according to the GDPR.
                        
                </Text>



                <Heading fontWeight="800" size="md" mt={6}><b>F: How Do We Protect Users' Personal Information?</b></Heading>
        <Text fontSize="md" mt={2}>
        PathPal AI employs rigorous technical, physical, and administrative safeguards designed to offer reasonable protection for Users' Personal Information from loss, misuse, unauthorized access, disclosure, and alteration. Security measures include strict information access authorization controls. However, given the inherent vulnerabilities of the online environment, PathPal AI cannot guarantee that these security measures will prevent illegally and maliciously operating third parties from accessing Users' Personal Information or the absolute security of that information during its transfer or storage on PathPal AI’s systems. Despite PathPal AI's commitment to securing its systems, the Website, and its Services, Users are responsible for keeping their password(s) and account registration information private and for ensuring that the Personal Information held by PathPal AI is accurate and up-to-date. Access to Users' Personal Information is permitted only among our employees and agents on a need-to-know basis and is executed under strict contractual confidentiality obligations when third parties process it.
        <br></br>
        <br></br>
In accordance with recommendations from the European Commission and relevant authority, PathPal AI has adopted the necessary precautions to maintain an adequate level of security, based on the nature of the personal data processed and the circumstances of the processing, to avoid, as far as possible and in line with current technology, its alteration, loss, or unauthorized access or processing.   
  
        
                </Text>

        
                <Heading fontWeight="800" size="md" mt={6}><b>G: Which are the Users´ Rights?</b></Heading>
        <Text fontSize="md" mt={2}>  
        PathPal AI respects the data protection rights of its Users. As such, Users should understand and accept that they have the following rights:
<br></br>
        <Text ml={10}>
       <ul>
       <br/>
        <li>The right to be informed: Users have the right to receive clear, transparent, and easily understandable information about how PathPal AI utilizes their Personal Information and their rights. This information is provided in this policy.</li>
        <br/>
        <li>The right to access and rectification: Users have the right to access, correct, or update their Personal Information at any time. It’s important for users to maintain their account information up-to-date directly with PathPal AI.</li>
       <br/>
        <li>The right to data portability: The personal data Users provide to PathPal AI is portable. This means it can be moved, copied, or transmitted electronically under certain circumstances.</li>
        <br/>
        <li>The right to be forgotten: Users can under certain conditions request that PathPal AI delete their data. If Users desire the deletion of the Personal Information PathPal AI holds, they should inform PathPal AI, and it will take reasonable steps to respond in accordance with legal obligations. If the Personal Information is no longer necessary for any purpose and is not legally mandated to be retained by PathPal AI, efforts will be made to delete, destroy, or permanently de-identify it.</li>
        <br/>
        <li>The right to restrict processing: Users may, under specific conditions, have the right to restrict the processing of their Personal Information.</li>
        <br/>
        <li>The right to object: Under certain circumstances, Users have the right to object to specific types of processing, such as direct marketing (i.e., receiving emails from PathPal AI about new offers or being contacted with various opportunities).</li>
        <br/>
        <li>The right to withdraw consent: If Users have granted consent to any process related to their Personal Information, they retain the right to withdraw that consent at any time (though withdrawing consent does not impugn the lawfulness of previous processing based on consent). Consent withdrawal regarding the processing of Personal Information can be done at any time by contacting PathPal AI via the details provided.</li>


       </ul>
       </Text>
       <br></br>
       Personal Data subjects can exercise the mentioned rights by submitting a request in writing to PathPal AI at teampathpal@gmail.com
    <br></br>
    <br></br>
In instances where Users believe their data protection rights have been breached, they can lodge a complaint with the supervisory authority in their country of residence. For Users in Europe, each EU Member State has an independent public authority for data protection that offers guidance on data protection issues and addresses complaints against violations of the General Data Protection Regulation (GDPR) and relevant national laws.

                </Text>

        
                <Heading fontWeight="800" size="md" mt={6}><b>H: Retention of Personal Information</b></Heading>
        <Text fontSize="md" mt={2}>  
        PathPal AI will retain Users' Personal Information for [ ] years. This retention of Personal Information allows PathPal AI to continually enhance the User experience with the Service. PathPal AI will conduct regular reviews of the held Personal Information and will securely delete or anonymize it when it is no longer necessary for legal, business, or customer needs.
        
                </Text>

                <Heading fontWeight="800" size="md" mt={6}><b>I: Links to Other Websites</b></Heading>
        <Text fontSize="md" mt={2}>  
        The Website contains links to third-party websites. If the User decides to follow a link to any of these third-party websites, they will be directed to that third party's website. The presence of a link on the PathPal AI site does not imply endorsement, authorization, or any affiliation with that third party by PathPal AI, nor does it signify an endorsement of their privacy or information security policies or practices. PathPal AI does not control these third-party websites. These sites may place their own cookies or other files on the User's computer, collect data, or solicit Personal Information from the User. PathPal AI encourages Users to read the privacy statements of other websites they visit.
        
                </Text>

                <Heading fontWeight="800" size="md" mt={6}><b>J: Shared Information</b></Heading>
        <Text fontSize="md" mt={2}>  
        Access to the User's information at PathPal AI is restricted to authorized employees and contractors and is only shared with third parties in the following instances:

        <br></br>
        <br></br>
                Other Service Providers, Users, Contractors, or Vendors: This includes sharing for purposes of accountancy, analytics and reporting, marketing and advertising, auditing, communication with the User, management of PathPal AI's customer database, and providing Users with booking services or trip information services through third-party websites.
                </Text>

                <Heading fontWeight="800" size="md" mt={6}>3. Changes to this Privacy Notice</Heading>
        <Text fontSize="md" mt={2}>  
        Changes to this Privacy Policy will be posted on this page. Should PathPal AI make a significant change to its privacy practices, notice will be provided on the Website and through any other contact information the User or Users have provided.
        </Text>

        <Heading fontWeight="800" size="md" mt={6}>4. Governing Law; Disputes</Heading>
        <Text fontSize="md" mt={2}>  
        This Privacy Policy is governed by the law of Enschede, The Netherlands. Any disputes arising from the interpretation or enforcement of this Privacy Policy or any other related matter shall be subjected to the laws of the municipality of Enschede and the exclusive jurisdiction of the courts in the judicial district of Enschede, The Netherlands.

        </Text>

        <Heading fontWeight="800" size="md" mt={6}>5. Contact Us</Heading>
        <Text fontSize="md" mt={2}>  
        If Users have any questions or concerns regarding PathPal AI’s Privacy Policy or data practices, they are encouraged to contact PathPal AI. All inquiries related to this Privacy Policy should be directed to teampathpal@gmail.com

        </Text>
                
      </Box>

      <Box textAlign="center" p={5}>
        <Button

         backgroundColor="#C8A0FF"
         borderRadius="9999px"
            _hover={{color: "white",backgroundColor: "#C8A0FF"}}
         color="white"
          leftIcon={<Icon as={FontAwesomeIcon} icon={faHome} />}
          onClick={() => navigate('/itinerary')}
        >
          Go back to homepage
        </Button>
      </Box>
    </Box>
    <FooterSection></FooterSection>
    </>
  );
}

export default Policy;