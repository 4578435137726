import React from 'react';
import { Box, Button, Flex, Text, Image, HStack } from '@chakra-ui/react';

const GetStartedGoogle = ({googleSignIn}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" padding="20px" border="1px solid rgb(238 238 238)" size="md" background="rgb(250 250 250)">
      <Text fontSize="md" fontWeight="1rem" w="50%">
      Sign in now to customize, edit, and personalize your journey with access to all our exciting features!
      </Text>
      <HStack>
      <Button borderRadius="9999px" onClick={googleSignIn} color="black" bg="white" fontWeight="500" _hover={{ bg: 'white' }} border="1px solid rgb(238 238 238)">
      <Image src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg'></Image>

        <Text  ml={2}>
        Sign in to start
            </Text>
      </Button>
      </HStack>

    </Flex>
  );
};

export default GetStartedGoogle;