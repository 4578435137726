import {
    Divider,
    Flex,
    Heading,
    Box,
    Link,
    SimpleGrid,
    Image,
    Text,
    VStack,
  } from '@chakra-ui/react'
  import { Link as ReactRouterLink } from 'react-router-dom'


//   import { SiFacebook, SiTwitter, SiGithub, SiLinkedin } from 'react-icons/si'
import logo from '../logocut.png'

  
  export const FooterSection = () => {
    // const bg = useColorModeValue('gray.100', 'gray.800')
  
    return (
      <>
      <footer>
  <Divider borderColor="rgb(224 224 224)"/>
  <VStack spacing={5} w="full" align="center" px={6} py={4}>
    <Flex direction={['column', 'column', 'row']}>
      <VStack mb={{"base":4,"md":0}} spacing={2} align="flex-start" w={{ base: 'full', lg: 2 / 5 }} mr={[4, 6, 8]}>
        <Flex align="center"> 
          <Box mr={3}> 
            <Image src={logo} alt="PathPal Logo" width="2.6rem" height="2.4rem"/>
          </Box>
          <Heading fontSize="1.5rem" fontWeight="500" className='tshad' color="black">PathPal</Heading>
        </Flex>
        <Text color="#9e9e9e" className='tshad2' fontWeight="500" fontSize="1rem">
          Turn your next journey into a personalised adventure with PathPal
        </Text>
      </VStack>
      <SimpleGrid columns={[2]} flex={1} gap={6} mt={3}>
        <VStack align="center">
          <Heading fontSize="sm" className="tshad" fontWeight="600 !important;" color="black" textTransform="uppercase">
            Legal
          </Heading>
          <Link to="/terms" as={ReactRouterLink} _hover={{
           color: "black",
           textDecoration: "underline"
         }}

         _visited={{
            color: "black",
            textDecoration: "none !important"
          }}
         
         color="black" className="tshad" fontSize="1rem">Terms & Conditions</Link>
          <Link   to="/privatepolicy" as={ReactRouterLink}  _hover={{
           color: "black",
           textDecoration: "underline"
         }}

         _visited={{
            color: "black",
            textDecoration: "none !important"
          }}
         
         color="black" className="tshad" fontSize="1rem">Privacy Policy</Link>
        </VStack>
        
        <VStack align="center">
          <Heading fontSize="sm" className="tshad" fontWeight="600 !important;" color="black" textTransform="uppercase">
            Support
          </Heading>
          <Link isExternal href='mailto:teampathpal@gmail.com'  _hover={{
           color: "black",
           textDecoration: "underline"
         }} 

         _visited={{
            color: "black",
            textDecoration: "none !important"
          }}
         
         color="black" className="tshad" fontSize="1rem">Contact Us</Link>
          <Link _hover={{
           color: "black",
           textDecoration: "underline"
         }}

         _visited={{
            color: "black",
            textDecoration: "none !important"
          }}
         
         color="black" className="tshad" fontSize="1rem">B2B Integration</Link>
        </VStack>
      </SimpleGrid>
    </Flex>
    <Divider borderColor="rgb(224 224 224)" w="50%"/>
    <Text fontSize="1rem" color="#9e9e9e" className='tshad2' fontWeight="500 !important">© {new Date().getFullYear()} PathPal. All rights reserved</Text>
  </VStack>
</footer>
      </>
    )
  }
  export default FooterSection