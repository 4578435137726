import React from 'react';
import { Box, Heading, Text, Button, Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import FooterSection from './FoooterSection'


function Terms() {
  const navigate = useNavigate();

  return (
    <>
    <Box>
      <Box bg="#C8A0FF" p={5}>
        <Heading color="white" textAlign="center">Terms & Conditions</Heading>
      </Box>

      <Box p={5}>
        <Text fontSize="md" as='p' mt={4}>
        Welcome to PathPal (https://pathpal.app/) (the “Website”) managed by PathPal AI, domiciled at Jannicksweg 122, 7513DN, Enschede, The Netherlands (“PathPal AI”). 
        The aim of this Website is to facilitate the travel planning process for its users (hereinafter referred to as the "Users").
        <br/>
        <br/>
These terms and conditions exclusively govern the relationship between PathPal AI and its Users.
<br/>
<br/>
<Text fontWeight="800 !important">
BY COMPLETING THE REGISTRATION FORM (THE “REGISTRATION FORM”) WHICH SIGNIFIES AN INTENTION TO UTILIZE THE SERVICES PROVIDED (AS DEFINED BELOW), USERS CONFIRM THAT THEY HAVE READ AND UNDERSTOOD THE TERMS PRESENTED IN THIS TERMS OF USE, INCLUDING THE TERMS OF THE PRIVACY POLICY AS THEY MAY BE AMENDED OVER TIME (COLLECTIVELY REFERRED TO AS THE "TERMS"), AND AGREE TO ADHERE TO THEM WHILE COMPLYING WITH ALL APPLICABLE LAWS AND REGULATIONS PERTAINING TO THE USE OF THIS WEBSITE AND/OR SERVICES. THESE TERMS GOVERN USERS’ ACTIVITIES AND DEFINE THEIR RIGHTS AND OBLIGATIONS UNDER THIS AGREEMENT. IF USERS DO NOT ACCEPT THESE TERMS, THEY ARE ADVISED NOT TO USE THIS WEBSITE AND/OR ANY SERVICES OFFERED BY PATHPAL AI.

</Text>
        </Text>

        <Heading  fontWeight="800" size="md" mt={6}>1. THE SERVICES</Heading>
        <Text fontSize="md" mt={2}>
        1.1. PathPal offers a digital platform through which Users can optimize their travel arrangements efficiently. PathPal will collaborate with Users to generate personalized travel itineraries to their chosen destination based on their preferences and needs (the "Services"). Users are required to provide their travel dates and destination to begin the planning process. PathPal will assist Users in scheduling the following:

       <Text ml={10}>
       <ul>
       <br/>
        <li> Activities: Users can explore various activities available at their destination and select a minimum number of activities per day. PathPal will organize the trip itineraries based on the User's preferences, budget, and travel location.</li>
        <br/>
        <li> Hidden Gems: Users can explore various hidden and local spots available at their destination and select add then to an existing itinerary.</li>
       <br/>
        <li> Top Sights: Users can explore top sights available at their destination and select add then to an existing itinerary.</li>
        <br/>
       </ul>
       </Text>

       The User may decide to share its trip plan made by Trip Planner AI with third parties. By clicking on the corresponding button, the User’s trip plan will be shared with third party users who form part of the Community Trips tab.
       <br/>
       <br/>

        1.3. The User understands and accepts that the User is responsible for all the decisions he/she/it makes regarding the assigned schedule, since it is defined according to the User's needs. The User shall be the one who decides to select the Activities, Hotels and Meals and shall have no claims against Trip Planner AI in this regard.
        <br/>
        <br/>
        1.4. The Parties agree that the collaboration in the organization of the trip may only be carried out for a limited amount of days.


        </Text>

        <Heading fontWeight="800" size="md" mt={6}>2. ACCESS & REGISTRATION</Heading>
        <Text fontSize="md" mt={2}>
        In order to be an Trip Planner AI User and use the Services, it is essential that Users complete the following steps:
        <Text ml={10}>
       <ul>
       <br/>
        <li>Login with their Google Account.</li>
        <br/>
        <li> Agree to the Terms and Conditions.</li>
       <br/>
        <li> Agree to the Private Policy.</li>
        <br/>
        <li> Agree to the Cookies Policy.</li>
        <br/>
       </ul>
       </Text>
       2.2. Users warrant that all the information regarding its identity and capacity provided to PathPal is true, accurate and complete. In addition, Users undertakes to keep their details updated.
       <br/>
       <br/>
        2.3. If a User provides any false, inaccurate or incomplete information, documentations or if Pathpal considers that there are well founded reasons to doubt the truthfulness, accuracy or integrity of such information, PathPal may deny the User’s current or future access to, and use of, the Website or any of its contents and/or Services.
        <br/>
        <br/>
        2.4. The User will be able to create an account in Pathpal through Google Authentication (the “Account”).
        <br/>
        <br/>
        2.5. Users undertake not to disclose the details of their Account or allow access thereto to third parties. Users shall be solely responsible for any use of such details that may be made by third parties, including for statements made on the Website or any other action carried out under their Account. Users must immediately immediately inform PathPal, by sending an email to teampathpal@gmail.com, if their Account is stolen, disclosed or lost.
        </Text>

        <Heading fontWeight="800" size="md" mt={6}>3. BOOKING PROCESS</Heading>
        <Text fontSize="md" mt={2}>
        3.1. The User acknowledges and agrees that to incorporate activities, attractions or transport options into their plans, the User may have to utilize third-party services. Notwithstanding the aforementioned, the User acknowledges and agrees that should they be redirected to third-party websites or platforms, any legal agreements will be exclusively between the User and these third-party platforms, and the User will have no grounds for claims against PathPal AI.
        <br></br>
        <br></br>
3.2. Hence, the User understands and accepts that any bookings, cancellations, or changes to their planned activities, attractions, or transport methods must be managed through third parties. PathPal AI disclaims responsibility for the booking or cancellation of these elements as they are not part of its direct Services.
        </Text>

        <Heading fontWeight="800" size="md" mt={6}>4. WARRANTIES AND LIMITATION OF LIABILITIES</Heading>
        <Text fontSize="md" mt={2}>
        4.1. PathPal AI simply equips Users with a platform to facilitate their travel planning by providing the needed tools for itinerary creation and optimization.
        <br></br>
        <br></br>

        4.2. The User acknowledges and accepts that when engaging with PathPal AI’s services, they do so at their own risk. Under no circumstances will PathPal AI be liable for lost profits, or any other form of damage or harm that the User might experience as a result of the services rendered through the Website.
        <br></br>
        <br></br>
        4.3. Users must understand that PathPal AI does not directly offer any personal travel service, tours, or logistical travel arrangements. It is solely the User’s responsibility to plan, gather necessary information, and execute their travel itinerary. PathPal’s role is to provide tools for planning and integrating diverse travel activities through data and algorithmic suggestions on the Website. PathPal AI will not bear any responsibility for the actual realization or outcomes of the travel plans, including but not limited to the conduct, errors, representations, warranties, or negligence of any third party linked via the platform or any personal injury, death, property damage, or other harms or expenses that might arise from such external engagements. Furthermore, PathPal AI is not liable for any interruptions such as delays, cancellations, overbookings, strikes, force majeure, or other situations beyond its direct control.
        <br></br>
        <br></br>
        4.4. Should there be an incident where PathPal AI is found accountable for any loss or damage resulting from or associated with the use of its Website or Services, the entire liability of PathPal AI towards the User shall not exceed and is limited to the sum of USD 1,000.
        </Text>


        <Heading fontWeight="800" size="md" mt={6}>5. USERS OBLIGATIONS</Heading>
        <Text fontSize="md" mt={2}>
        5.1. Users are fully responsible for the appropriate use of and access to the Website and their account in compliance with applicable laws, whether national or international, of the country from which the Website is accessed, along with principles of good faith, ethics, accepted customs, and public order. Specifically, they commit to diligently adhere to these User Terms and Conditions.
        <br></br>
        <br></br>
5.2. Users must not use their account and the Website's contents for illegal purposes or outcomes that infringe on third-party rights and interests, or that could in any manner damage, disable, compromise, or degrade the Website, its content, and Services. Moreover, they are prohibited from obstructing the normal use and enjoyment of the Website by other Users.
<br></br>
<br></br>
5.3. Any individuals violating these obligations will be held accountable for any resultant loss or damage. PathPal AI will not assume liability for any consequences, loss, or damage arising from such illicit use or third-party access.
<br></br>
<br></br>
5.4. In general, Users are required to, but not limited to:
<br></br>
<Text ml={10}>
       <ul>
       <br/>
        <li>Avoid altering or modifying the Website, wholly or partly, by circumventing, disabling, or in any other manner tampering with its functions or Services.</li>
        <br/>
        <li>Refrain from violating industrial and intellectual property rights or regulations concerning personal data protection.</li>
       <br/>
        <li>Avoid accessing other Users’ email accounts.</li>
        <br/>
        <li>Refrain from uploading computer viruses, corrupted files, or any other malicious software that may cause damage or alterations to PathPal AI’s or third parties’ contents or systems.</li>
        <br/>
       </ul>
       </Text>

5.5. Any User may report another User believed to be in breach of these User Terms and Conditions. Similarly, any User may notify PathPal AI of any misuse or violation of these terms by sending an email to PathPal AI at teampathpal@gmail.com. PathPal AI will review such reports promptly and will take any action deemed appropriate, reserving the right to remove and/or suspend any User from the Website due to breaching these terms. Furthermore, PathPal AI reserves the right to delete and/or suspend any message containing illegal or offensive content without prior warning or subsequent notification.
        </Text>

        <Heading fontWeight="800" size="md" mt={6}>6. INDEMNIFICATION</Heading>
        <Text fontSize="md" mt={2}>
        6.1. To the extent permitted under applicable law, the User agrees to defend, indemnify, and hold PathPal AI harmless from and against any claims, lawsuits, investigations, penalties, damages, losses, or expenses arising out of or relating to any of the following:
        <br></br>
<Text ml={10}>
       <ul>
       <br/>
        <li>Any breach or alleged breach by the User of these User Terms and Conditions, or the representations and warranties made herein or in any agreement between the parties.</li>
        <br/>
        <li>Any violation or alleged violation of laws or regulations by the User.</li>
       <br/>
        <li>Avoid accessing other Users’ email accounts.</li>
        <br/>
        <li>Any claim by a User or a third party arising from or related to information provided by the User to PathPal AI and displayed on the Website, particularly if this information is erroneous, incomplete, inadequate, or misleading (except where the fault lies with PathPal AI in terms of how the information was published).</li>
        <br/>
        <li>Any claim by a User or a third party due to any delay, cancellation, overbooking, strike, force majeure, or other causes beyond the control of PathPal AI.</li>
        <br/>
        <li>Any claim arising from the User's misuse of data or any violation of applicable data privacy laws.</li>
        <br/>
       </ul>
       </Text>

        </Text>



        <Heading fontWeight="800" size="md" mt={6}>7. INTELLECTUAL PROPERTY</Heading>
        <Text fontSize="md" mt={2}>   
        7.1. All Intellectual Property Rights from, in and to the Website and/or the Service and the content on the Website, including, but not limited to, inventions, patents and patent applications, trademarks, trade names, copyrightable materials, graphics, text, designs, specifications, methods, procedures, algorithms, data, technical data, interactive features, system scripts, graphics, photos, sounds, music, videos, (source and object code), files, interface and trade secrets and the like, whether or not registered and PathPal AI trademarks, service marks and logos contained therein (collectively “Intellectual Property”), are solely owned by or licensed to PathPal AI, and subject to copyright and other Intellectual Property rights under applicable law. Except as provided herein, User is not granted, either expressly or by implication any license or right to use any of the Intellectual Property without the prior express written permission of PathPal AI.
        <br></br>
        <br></br>
        7.2. Authorizing a User to access the Website does not imply the waiver, transfer, licensing or full or partial assignment by PathPal AI of any intellectual property rights. Deleting, bypassing or in any way tampering with the contents of the PathPal AI Website are all prohibited. In addition, modifying, copying, reusing, exploiting, reproducing, publicizing, making second or subsequent publications of, uploading files, sending by post, transmitting, using, processing or distributing in any way all or some of the contents included in the PathPal AI Website for public or commercial purposes are also prohibited, save with PathPal AI’s express written authorization or, where applicable, that of the owner of the rights concerned.
        <br></br>
        <br></br>
        7.3. Any User who shares any content of any kind through the Website asserts that he/she/it has the necessary rights to do so, releasing PathPal AI from any liability regarding the content and lawfulness of the information supplied. By providing content through the Website, Users assign to PathPal AI, free of charge and to the maximum extent permitted by the current legislation, the exploitation rights on the intellectual or industrial property arising from such content.
        <br></br>
        <br></br>
        7.4. The User will be able, if he/she/it wishes, to inform his/her/its usernames of the social networks TikTok or Instagram. Additionally, the User will have the possibility to send to PathPal AI, the TikToks and/or reels that they make regarding the Service or use of the Service or trips that have been made under the organization predisposed by PathPal AI, with the purpose that PathPal AI can disclose such contents on its social networks and/or on the Website. The User authorizes the use of his/her/it’s personal image and/or contents by sending them to PathPal AI through the means that PathPal AI makes available for this purpose, without this giving rise to any claim against PathPal AI. PathPal AI is not obliged to use the content sent by the Users. If applicable, such content shall be used for a certain period of time, with the understanding that if the content remains in the PathPal AI feed of any social network, it shall not be understood that the content is being used.
        <br></br>
        <br></br>
        PathPal AI hereby grants you a non-exclusive, non-transferable, royalty-free license to use its trademarks for use in the Netherlands, provided that the User is a member of PathPal AI and for the sole purpose of performing the social media activities. The User understands and agrees that such license shall not be sufficient authorization to act on behalf of PathPal AI. The User must not take any action that would adversely affect the image and reputation of PathPal AI, or that would bring its ideals and objectives into dispute. Furthermore, PathPal AI shall have the right to require the exclusion of any element that may adversely affect its reputation or neutrality or may be inconsistent with its objectives, principles, policies or programs. The User shall not use the PathPal AI trademarks and logos for any use other than that set forth herein. The User shall refrain from using and/or requesting the registration of trademarks similar or identical to PathPal AI's trademarks, anywhere in the world, without authorization from PathPal AI.
        
                </Text>

    
    
                <Heading fontWeight="800" size="md" mt={6}>8. DATA PROTECTION</Heading>
        <Text fontSize="md" mt={2}>   
        8.1. PathPal respects the privacy of Users and is committed to safeguarding the personal information shared with it. PathPal believes Users have the right to be informed about the practices surrounding the data PathPal may collect when Users utilize the Service. These practices, as well as the type of information gathered, are described in the Privacy Policy of PathPal.
        <br></br>
        <br></br>
8.2. Except when the transfer of personal data to third-party companies is necessary to fulfill the purposes mentioned above, PathPal will refrain from disclosing, selling, leasing, or exchanging the personal data to any company or entity that is not affiliated with PathPal, without the User's explicit prior approval.
        
                </Text>


                <Heading fontWeight="800" size="md" mt={6}>9. FORBIDDEN ACTIVITIES</Heading>
        <Text fontSize="md" mt={2}>   
        Users may not:
        <Text ml={10}>
       <ul>
       <br/>
        <li>Copy, alter, adapt, translate, reverse engineer, decompile, or disassemble any part of the Website and/or the Service.</li>
        <br/>
        <li>Disrupt or interfere with the operation of the Website and/or the Service, or the servers or networks that host the Website and/or make the Service available, or disobey any requirements, procedures, policies, or regulations of such servers or networks.</li>
       <br/>
        <li>Avoid accessing other Users’ email accounts.</li>
        <br/>
        <li>Violate the privacy or other rights of users, or collect or harvest personal data about other users without their consent using any automated or manual process.</li>
        <br/>
        <li>Defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights of others, or publish, post, transfer, or distribute illegal, obscene, indecent, or hateful content via the Website and/or the Service.</li>

       </ul>
       </Text>
        
                </Text>



                <Heading fontWeight="800" size="md" mt={6}>10. MODIFYING THE TERMS AND CONDITIONS</Heading>
        <Text fontSize="md" mt={2}>   
        10.1. PathPal reserves the right to modify these Terms and Conditions at its discretion to reflect changes in its operations, the Website, services, or applicable laws. PathPal will provide Users with thirty (30) days prior notice of these changes via the Website and may also notify users through contact information, email, or other means. The revised Terms and Conditions will be effective as of the published effective date, and continuing the use of the Website or services after such changes will mean acceptance of the revised terms.
        <br></br>
        <br></br>
        10.2. PathPal is continually evolving and enhancing the Services. PathPal may introduce new features or functionalities, and may also suspend or discontinue a Service.
        <br></br>
        <br></br>
10.3. Users can cease using the Services any time. PathPal also reserves the right to discontinue providing Services to Users or to impose new limits on the Services at any time.
<br></br>
<br></br>
10.4. PathPal recognizes that Users own their data and prioritizes maintaining access to such data. Should PathPal discontinue a Service, it will, where reasonably possible, provide Users with adequate advance notice and an opportunity to extract their data from the Service.

        
                </Text>


                <Heading fontWeight="800" size="md" mt={6}>11. EXTERNAL WEBSITES</Heading>
        <Text fontSize="md" mt={2}>   
        11.1. The Website may contain links to third-party websites or applications ("External Websites"). These links are provided solely for convenience and do not constitute an endorsement by PathPal of the content on such External Websites. PathPal does not control these External Websites and makes no claims about the accuracy, quality, or accessibility of their content.
        <br></br>
        <br></br>
        11.2. The User acknowledges that PathPal has no control over External Websites. The User agrees that PathPal is not responsible for the availability of such sites, and does not endorse and is not liable for any services, content, advertisements, products, or other materials on or available from such sites. The User also agrees that PathPal shall not be held responsible or liable for any damages caused by the use of or reliance on any such content, goods, or services available on or through any External Websites. It is recommended to review the legal documents of any External Website carefully. PathPal reserves the right to terminate any external link at any time at its own discretion.
        
                </Text>

        
                <Heading fontWeight="800" size="md" mt={6}>12. CLAIMS AND QUESTIONS</Heading>
        <Text fontSize="md" mt={2}>   
        12.1. At any time, Users will have the possibility to ask for help to the PathPal team by using the following email address: teampathpal@gmail.com        
                </Text>

        
                <Heading fontWeight="800" size="md" mt={6}>13. FINAL PROVISIONS</Heading>
        <Text fontSize="md" mt={2}>  
        13.1. The invalidity or unenforceability of any provision of these Users Terms and Conditions shall not affect the validity of the remaining provisions. PathPal AI and the User will negotiate to replace any invalid or unenforceable provision with a valid one that achieves, to the greatest extent possible, the same effect as the original provision, aiming to reflect the original intentions of these Terms as closely as possible. The provisions of these Users Terms and Conditions are severable, ensuring that the remainder of the provisions remain valid and enforceable 
        <br></br>
        <br></br>
        13.2. These Users Terms and Conditions shall be governed by and construed in accordance with the laws of Enschede, The Netherlands. Any disputes arising between the parties that cannot be resolved amicably shall be resolved exclusively in the competent courts of Enschede, The Netherlands
        <br></br>
        <br></br>
        Date of these Users Terms and Conditions: 1/4/2024
                </Text>
      </Box>

      <Box textAlign="center" p={5}>
        <Button

         backgroundColor="#C8A0FF"
         borderRadius="9999px"
            _hover={{color: "white",backgroundColor: "#C8A0FF"}}
         color="white"
          leftIcon={<Icon as={FontAwesomeIcon} icon={faHome} />}
          onClick={() => navigate('/itinerary')}
        >
          Go back to homepage
        </Button>
      </Box>
    </Box>
    <FooterSection></FooterSection>
    </>
  );
}

export default Terms;