import {
    Box,
    Container,
    Image,
    Text,
    Grid,
    GridItem,
    useBreakpointValue
  } from '@chakra-ui/react';
  import React from 'react';
  import dest1 from './maploc.png'
  import dest2 from './vault.png'



  const KeyBenefits = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <>
        <Box fontSize={{ base: "1.875rem", md: "3rem" }} textAlign="center" mb={{base:"3vh",md:"10vh"}}>
    <Text as="span" color="black">Your </Text>
    <Text as="span" color="#C8A0FF">AI-powered </Text>
    <Text as="span" color="black">Planner</Text>
</Box>
<Box px={{ base: "0", md: "10" }} maxWidth={{base: 'auto', md: "80vw"}} mx="auto">
    <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={{ base: 0, md: 4 }} // Reduced gap for base; adjust as necessary
    >
        {/* Row 1 */}
        <GridItem pl={{base: 0,md: 20}} colSpan={{ base: 1, md: 1 }}>
           {isMobile ? (
           <Box p="6"  mt={{ base: 3, md: 5 }}>
                <Text lineHeight="1.75rem" fontSize={{base:"1.5rem",md:"1.875rem"}} className='tshad' fontWeight="normal">The most optimal way of travel</Text>
                <Text mt="2" lineHeight="1.75rem" color="#757575" className='tshad' fontWeight="normal" fontSize="1rem">
                    Our advanced AI algorithms intricately weave together your chosen destinations, preferred attractions, and date ranges to craft an itinerary tailored to your unique journey.
                </Text>
            </Box>
       ) : (<Container>
           <Box p="6"  mt={{ base: 3, md: 5 }}>
                <Text lineHeight="1.75rem" fontSize={{base:"1.5rem",md:"1.875rem"}} className='tshad' fontWeight="normal">The most optimal way of travel</Text>
                <Text mt="2" lineHeight="1.75rem" color="#757575" className='tshad' fontWeight="normal" fontSize="1rem">
                    Our advanced AI algorithms intricately weave together your chosen destinations, preferred attractions, and date ranges to craft an itinerary tailored to your unique journey.
                </Text>
            </Box>
           </Container>)


           }
        </GridItem>
       
        <GridItem pr={{md:20}} colSpan={{ base: 1, md: 1 }} mb={10}>
            <Box display="flex" justifyContent="center" alignItems="center" p="2">
               <Image src={dest1} alt="AI Trip planner travel trip planner ai" boxSize={{ base: "60%", md: "48%" }} objectFit="cover" />
            </Box>
        </GridItem>

        {/* Row 2 */}
        {isMobile ? (
        <>
        
        <GridItem pl={{base: 5}} pr={{md: 0 ,base: 0}} colSpan={{ base: 1, md: 1 }}>
        {isMobile ? (
            <Box p="1" pl={3} mt={{ base: 3, md: 5 }}>
                <Text fontSize={{base:"1.5rem",md:"1.875rem"}} className='tshad' fontWeight="normal" lineHeight="1.75rem">Find unique hidden places</Text>
                <Text mt="2" lineHeight="1.75rem" color="#757575" className='tshad' fontWeight="normal" fontSize="1rem">
                    Our AI searches the globe and extracts lesser-known attractions, activities and hidden gems that offer unique experiences, allowing you to effortlessly include them in your own adventure.
                </Text>
            </Box>
        ) : (<Container pt={3}>
            <Box p="1" pl={3} mt={{ base: 3, md: 5 }}>
                <Text fontSize={{base:"1.5rem",md:"1.875rem"}} className='tshad' fontWeight="normal" lineHeight="1.75rem">Find unique hidden places</Text>
                <Text mt="2" lineHeight="1.75rem" color="#757575" className='tshad' fontWeight="normal" fontSize="1rem">
                    Our AI searches the globe and extracts lesser-known attractions, activities and hidden gems that offer unique experiences, allowing you to effortlessly include them in your own adventure.
                </Text>
            </Box>
            </Container>)
        }
        </GridItem>
        <GridItem pl={{base:0,md: 20}}  mb={{base: "none",md: "15vw"}} colSpan={{ base: 1, md: 1 }}>
            <Box display="flex" justifyContent="center" alignItems="center" p="2"> 
                <Image src={dest2} alt="AI Trip planner travel trip planner ai" boxSize={{ base: "70%", md: "39%" }} objectFit="cover" />
            </Box>
        </GridItem>
        </>

        ) : (
            <>
        <GridItem pl={{base:0,md: 20}}  mb={{base: "none",md: "10vw"}} pb={8} colSpan={{ base: 1, md: 1 }}>
            <Box display="flex" justifyContent="center" alignItems="center" p="0"> 
                <Image src={dest2} alt="AI Trip planner travel trip planner ai" boxSize={{ base: "60%", md: "70%" }} objectFit="cover" />
            </Box>
        </GridItem>
        <GridItem pl={{base: 5}} pr={{md: 0 ,base: 0}} colSpan={{ base: 1, md: 1 }}>
        {isMobile ? (
            <Box p="1" pl={3} mt={{ base: 3, md: 5 }}>
                <Text fontSize={{base:"1.5rem",md:"1.875rem"}} className='tshad' fontWeight="normal" lineHeight="1.75rem">Find unique hidden places</Text>
                <Text mt="2" lineHeight="1.75rem" color="#757575" className='tshad' fontWeight="normal" fontSize="1rem">
                    Our AI searches the globe and extracts lesser-known attractions, activities and hidden gems that offer unique experiences, allowing you to effortlessly include them in your own adventure.
                </Text>
            </Box>
        ) : (<Container pt={3}>
            <Box p="1" pl={3} mt={{ base: 3, md: 5 }}>
                <Text fontSize={{base:"1.5rem",md:"1.875rem"}} className='tshad' fontWeight="normal" lineHeight="1.75rem">Find unique hidden places</Text>
                <Text mt="2" lineHeight="1.75rem" color="#757575" className='tshad' fontWeight="normal" fontSize="1rem">
                    Our AI searches the globe and extracts lesser-known attractions, activities and hidden gems that offer unique experiences, allowing you to effortlessly include them in your own adventure.
                </Text>
            </Box>
            </Container>)
        }
        </GridItem>
        </>
        )}
    </Grid>
</Box>
  </>
    )
  }

export default KeyBenefits