import { Image,AlertDialog,AlertDialogOverlay, DrawerOverlay, Drawer,DrawerContent,DrawerHeader,DrawerCloseButton, AlertDialogCloseButton, DrawerBody, AlertDialogContent,AlertDialogHeader,Flex,Heading,Text,AlertDialogBody,Button,Center,Box, useBreakpointValue } from "@chakra-ui/react"
import logo from './logocut.png'


const LoginAlert = ({isOpen,onClose,login}) => {
    const isMobile = useBreakpointValue({base: true, md:false})

    return (
        <>

        {isMobile ? (
                 <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
                 <DrawerOverlay backdropFilter='blur(10px)'>
                 
                   <DrawerContent
                     maxH="fit-content"
                     w="full"
                     maxW="md"
                     overflow="hidden"
                     borderWidth="1px"
                     borderRadius="md"
                     boxShadow="xl"
                   >
                     <DrawerCloseButton />
                     <DrawerHeader fontSize="2xl" fontWeight="bold" textAlign="center"  py={7}>
                       <Flex alignItems="center" justifyContent="center" flexDirection="column">
                           <Image
                             alt="PathPal: AI Trip planner"
                             loading="lazy"
                             width="3rem"
                             height="3rem"
                             decoding="async"
                             src={logo}
                           />
                         <Heading fontSize="1.5rem" lineHeight="2rem" fontWeight="700">
                           Sign In
                        </Heading>
                        <Text fontSize=".875rem" color="rgb(158 158 158)" fontWeight="500" className='tshadbtn'>
                             Sign in to start building amazing journeys on demand and accesss all the features.
                        </Text>
                      </Flex>
                    </DrawerHeader>
         
                    <DrawerBody px={0}>
                       <Box bg="rgb(250 250 250)" py="3vh">
                        <Center px="4vw">
                        <Button
                           w="20vw"
                           height="10vh"
                           bg="white"
                           color="black"
                           fontWeight="inherit"
                           _hover={{ bg: 'rgb(250 250 250)' }}
                           flex="1"
                           fontSize="0.875rem"
                           h="2.5rem"
                           borderRadius="0.375rem"
                           textTransform="none"
                           className='signin'
                           onClick={login}
                         >
                           <Image alt="ai trip planner travel planner ai" src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg' width="20px" height="20px" style={{marginRight: "0.5rem"}}>
                           </Image>
                           <Text>Sign In with Google</Text>
                         </Button>
                       </Center>
                      </Box>
                    </DrawerBody>
                  </DrawerContent>
                </DrawerOverlay>
              </Drawer>
        ) : (
            <AlertDialog className='signin'
            isOpen={isOpen}
            
            onClose={onClose}
            onOverlayClick={onClose} // Close the dialog when clicking outside
            isCentered
          >
            <AlertDialogOverlay backdropFilter='blur(10px)' />
    
            <AlertDialogContent
              maxH="fit-content"
              w="full"
              maxW="md"
              overflow="hidden"
              borderWidth="1px"
              borderRadius="md"
              boxShadow="xl"
            >
                <AlertDialogCloseButton></AlertDialogCloseButton>
              <AlertDialogHeader fontSize="2xl" fontWeight="bold" textAlign="center"  py={7}>
                <Flex alignItems="center" justifyContent="center" flexDirection="column">
                    <Image
                      alt="PathPal: AI Trip planner"
                      loading="lazy"
                      width="3rem"
                      height="3rem"
                      decoding="async"
                      src={logo}
                    />
                  <Heading fontSize="1.5rem" lineHeight="2rem" fontWeight="700">
                    Sign In
                  </Heading>
                  <Text fontSize=".875rem" color="rgb(158 158 158)" fontWeight="500" className='tshadbtn'>
                    Sign in to start building amazing journeys on demand and accesss all the features.
                  </Text>
                </Flex>
              </AlertDialogHeader>
    
              <AlertDialogBody px={0}>
                <Box bg="rgb(250 250 250)" py="3vh">
                <Center px="4vw">
                <Button
               
                    // border="1px"
                    w="20vw"
                    height="10vh"
                    // borderColor="rgb(238 238 238)"
                    bg="white"
                    color="black"
                    fontWeight="inherit"
                    _hover={{ bg: 'rgb(250 250 250)' }}
                    flex="1"
                    fontSize="0.875rem"
                    h="2.5rem"
                    borderRadius="0.375rem"
                    textTransform="none"
                    className='signin'
    
              
                    onClick={login}
                  >
                    <Image alt="ai trip planner travel planner ai" src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg' width="20px" height="20px" style={{marginRight: "0.5rem"}}>
    
                    </Image>
    
                    <Text>Sign In with Google</Text>
                  </Button>
                </Center>
                </Box>
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialog>
        )}
            
        </>
    )
}

export default LoginAlert