import React from 'react';
import { useState } from 'react';
import { Box, Flex, Text, Button, Avatar, Menu, DrawerBody,DrawerHeader,Input,DrawerFooter, MenuButton, MenuItem, MenuList, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, VStack } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useBreakpointValue } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logocut.png'
import { Divider } from 'rsuite';


const NavBar = ({ profile, logOut,onOpen }) => {
    const modal1 = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const history = useNavigate()

    const goToItinerary = () => {
      history('/itinerary');
    }

    const goToSettings = () => {
      history('/settings');
    }

    return (
      <Flex bg="white" w="full" zIndex={1} top={0} p={4} px={{base:10,md:10}} className='signin' justify="space-between" position="sticky">
        <Flex align="center">
          <img src={logo} alt="PathPal: AI trip planner" height="40rem" width="40rem" style={{marginRight: 10}}/>
          <Link to={localStorage.getItem("profile") ? "/itinerary" : "/"} style={{textDecoration: "none"}} className='tshad' color='black'>
          <Text textDecoration="none" color="black!important;" _hover={{color: "black",textDecoration: "none"}} fontSize="1.25rem" mr={4}  _visited={{color: "black",textDecoration: "none"}}>
            Pathpal
          </Text>
          </Link>
          {!isMobile && <Link to="/new-trip"  className='tshad'>
            <Button className='tshad'
              variant="outline" 
              fontWeight="500"
              color="black"
              p={3}
              borderColor="transparent"
              _hover={{ bg: "rgb(245 245 245)", borderColor: "rgb(245 245 245)" }} 
              borderRadius="full"
              ml={4}
            >
              New Trip
            </Button>
          </Link>}
        </Flex>
       {profile ? (
        <>
        {isMobile ? (
            <>
            <Button variant="ghost" onClick={modal1.onOpen}>
              <HamburgerIcon boxSize={6} />
            </Button>
            <Drawer placement="bottom" onClose={modal1.onClose} isOpen={modal1.isOpen} size="lg">
              <DrawerOverlay backdropFilter='blur(10px)'>
                <DrawerContent pb={4}>
                  <DrawerCloseButton />
                  {/* <MenuList> */}
                    <Box p={2} m={1} ml={2} pointerEvents="none">
                      <Text isTruncated fontSize="0.875rem" className='tshad' fontWeight="500">{profile.name}</Text>
                      <Text isTruncated fontSize="0.875rem" fontWeight="500" className='tshad2' color="#9e9e9e">{profile.email}</Text>
                    </Box>
                    <Divider style={{margin: "5px 0"}}></Divider>
                    <VStack pt={3} align="left" gap={3} ml={5}> 
                    <Link to="/itinerary" style={{"textDecoration":"none"}}>
                    <Text fontWeight="500" className='tshad' fontSize="0.875rem" color="black">My Trips ✈️</Text>
          </Link>
          <Link to="/settings" style={{"textDecoration":"none"}}>
                    <Text fontWeight="500" className='tshad' fontSize="0.875rem" color="black">Settings</Text>
          </Link>
                    <Link to="/new-trip" style={{"textDecoration":"none"}}>
                    <Text fontWeight="500" className='tshad' fontSize="0.875rem" color="black">New Trip</Text>
          </Link>
                    <Text onClick={logOut} fontSize="0.875rem" color="black"  className='tshad' >Logout</Text>

                    </VStack>                   
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </>
        ) : (
             <Menu>
             <MenuButton style={{background: "none !important"}} as={Button} p={0} variant="ghost" _hover={{background: "none !important"}}  _active={{background: "none !important"}}>
               <Avatar size="sm" src={profile.picture} referrerPolicy="no-referrer" />
             </MenuButton>
             <MenuList>
             <Box m={2} pointerEvents="none">
             <Text isTruncated fontSize="0.875rem" className='tshad' fontWeight="500">{profile.name}</Text>
             <Text isTruncated fontSize="0.875rem" fontWeight="500" className='tshad2' color="#9e9e9e">{profile.email}</Text>
             <Divider style={{margin: "5px 0"}}></Divider>
             </Box>
             <MenuItem backgroundColor="transparent" _hover={{"backgroundColor":"#f5f5f5"}} onClick={goToItinerary}>My Trips ✈️</MenuItem>
             <MenuItem backgroundColor="transparent" _hover={{"backgroundColor":"#f5f5f5"}} onClick={goToSettings}>Settings</MenuItem>


             <MenuItem backgroundColor="transparent" _hover={{"backgroundColor":"#f5f5f5"}} onClick={logOut}>Logout</MenuItem>
           </MenuList>
           </Menu>
        )}
        </>
       ): (
        <Button
        id='shadow'
              color='white'
              borderRadius="9999px"
              backgroundColor="black"
              _hover={{color: "white",backgroundColor: "black"}}
              fontWeight="500"
              fontSize="0.875rem"
              onClick={onOpen}
            >
              Sign In
            </Button>
       )}
      </Flex>
    );
  }
  
  export default NavBar;