import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HStack, VisuallyHidden, chakra } from '@chakra-ui/react'

import 'rsuite/dist/rsuite.min.css';
import { AlertDialog, Box,Slider, Popover, VStack, useRadio,useRadioGroup, PopoverCloseButton,  Icon, Grid, useCheckbox, PopoverTrigger,PopoverContent, PopoverHeader, PopoverBody, IconButton, SliderTrack,SliderFilledTrack, SliderThumb, AlertDialogBody, Text, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from "@chakra-ui/react";
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { Container, FormLabel, Heading, Button, useBreakpointValue } from '@chakra-ui/react';


import styles from './styles/MainPage.module.css'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { formatActivitiesList} from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTravel } from './TravelContext';
import FooterSection from './landing/FoooterSection'



import { faCheckCircle, faShoppingBag, faLandmark, faTree, faPersonWalking, faPersonWalkingLuggage, faPersonHiking, faHandSparkles, faChildReaching, faSeedling,faScroll,faCloudSun,faMonument,faPalette, faDungeon,faLocationDot } from '@fortawesome/free-solid-svg-icons';




const activityIcons = {
  'Historical Sites': faScroll,
  'Shopping': faShoppingBag,
  'Cultural Landmarks': faMonument,
  'Outdoor Adventures': faCloudSun,
  'Must-see Attractions': faLocationDot,
  'Nature & Parks': faTree,
  "Arts & Theatre": faPalette,
  "Amusement Parks": faDungeon,
  "Museums": faLandmark,
  "Botanical Exploration": faSeedling,
  "Kid-Friendly": faChildReaching,
  "Obscure Places": faHandSparkles
};

const options = ['Historical Sites','Shopping', 'Cultural Landmarks','Outdoor Adventures', 'Nature & Parks','Must-see Attractions',"Arts & Theatre","Amusement Parks","Museums","Botanical Exploration","Kid-Friendly", "Obscure Places"]; // Add more options as needed



const ActivitiesPage = () => {
  
  const history = useNavigate(); // Get the history object from React Router
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading,setLoading] = useState(false);
  const [hiddenGemLevel, setHiddenGemLevel] = useState("Medium");
  let [additionalInfo, setAdditionalInfo] = useState("")
  const { travelData, updateTravelData } = useTravel();
  const mainTitle = `What to do in ${travelData.geoValue}? 🤩`
  let packedness = 'Touristy (2-3 activities)'
  let chosenActivities = [];
  if (travelData.geoValue == "") {
    if (JSON.parse(localStorage.getItem('profile'))) {
      history('/itinerary')
    } else {
      history('/new-trip')
    }
   
  }

  const [sliderValue, setSliderValue] = useState(1); // Initial value, can be 0, 1, or 2 for Low, Medium, High
  
  const ActivitiesPerDayCard = ({ label, icon, ...props }) => {
    const { getInputProps, getCheckboxProps, getRadioProps, htmlProps, state } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();
    const radio = getRadioProps();
  
    return (
      <Box as="label" {...radio} {...htmlProps}>
        <input {...input} />
        <Box
          {...checkbox}
          display="flex"
          flexDirection="row"
          alignItems="center"
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          _checked={{
            borderColor: 'black',
          }}
          _hover={{
            borderColor: '#bdbdbd',
          }}
          p={{base: 3, md: 2}}
          gap={3} // Adjust the space between the icon and text as needed
          minWidth="13vw"
          maxW={{base:"60vw",md:"auto"}}
        >
          {/* Circular div with icon */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            w={{base:"10vw",md:"2vw"}} h={{base:"10vw",md:"2vw"}}// Adjust the size of the background circle as needed
            bg={state.isChecked ? "#DCFFA0" : "#EEEEEE"} // Change the background color based on selection
          >
            <Icon fontSize="1.25rem" as={FontAwesomeIcon} icon={icon} color={state.isChecked ? "black" : "#bdbdbd"} />
          </Box>
  
          {/* Vertical stack for texts */}
          <VStack alignItems="start" spacing={0}>
            <Text className='tshad' fontWeight="500" fontSize="1rem">{label.split("(")[0]}</Text>
            <Text color="#9E9E9E" fontSize=".875rem">{`${label.split("(")[1].replace(")","")}`}</Text>
          </VStack>
  
        </Box>
      </Box>
    );
  };
  
    const ActivitiesPerDaySelection = () => {
    const options = [
      { value: 'Chill (1-2 activities)', label: 'Chill (1-2 activities)', icon: faPersonWalking },
      { value: 'Touristy (2-3 activities)', label: 'Touristy (2-3 activities)', icon: faPersonWalkingLuggage },
      { value: 'Packed (3+ Activities)', label: 'Packed (3+ Activities)', icon: faPersonHiking },
    ];
  
    const { getRootProps, getRadioProps } = useRadioGroup({
      name: 'activitiesPerDay',
      defaultValue: 'Touristy (2-3 activities)',
      onChange: (value) => {
        packedness = value


      },
    });
  
    const group = getRootProps();
  
    return (
      <div>
        {isMobile ? (
          <VStack {...group} justifyContent="center" spacing={5}>
          {options.map((option) => (
            <ActivitiesPerDayCard
              key={option.value}
              {...getRadioProps({ value: option.value })}
              label={option.label}
              icon={option.icon}
            />
          ))}
        </VStack>
        ) : (
          <HStack {...group} justifyContent="center" spacing={5}>
        {options.map((option) => (
          <ActivitiesPerDayCard
            key={option.value}
            {...getRadioProps({ value: option.value })}
            label={option.label}
            icon={option.icon}
          />
        ))}
      </HStack>
        )}
      </div>
    );
  };
  

  // activities
  const ActivityCard = ({ icon, value, onChange, ...props }) => {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);
    
    // Execute the provided onChange callback whenever the component is clicked (checked or unchecked)
    const handleClick = useCallback(() => onChange(value, !state.isChecked), [onChange, value, state.isChecked]);
  
    return (
      <chakra.label
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p={5}
        minW="0"
        onClick={handleClick} // Attach the click event handler
        borderWidth="1px"
        borderRadius="lg"
        maxW={{base:"80vw",md:"18vw"}}
        height="auto"
        transition={state.isChecked ? "color 0.3s ease, box-shadow 0.3s ease" : "color 0.3s ease"}
        boxShadow= {state.isChecked ? "md" : "none"}
        borderColor={state.isChecked ? "blackAlpha.800" : "#bdbdbd"}
        // bg={state.isChecked ? "white" : "white"}
        color={state.isChecked ? "black" : "#bdbdbd"}
        _hover={{
          transition: "color 0.3s ease",
          borderColor: state.isChecked ? "blackAlpha.800" : "#bdbdbd",
          color: state.isChecked ? "black" : "#757575",
        }}
        cursor="pointer"
        {...htmlProps}
      >
        <input {...getInputProps()} hidden />
        <Icon  as={FontAwesomeIcon} icon={activityIcons[icon]} mr={2} mb={1} fontSize="1.25rem" lineHeight="1.75rem" />
        <Text fontSize="1.125rem" flex="1" textAlign="left" {...getLabelProps()}>{props.children}</Text>
         <Icon fontSize="1.25rem" lineHeight="1.75rem" as={FontAwesomeIcon} icon={faCheckCircle} />
      </chakra.label>
    );
  };
  
  const ActivitiesSelection = () => {
    const [selectedActivities, setSelectedActivities] = useState([]);
  
    const handleActivityChange = useCallback((activity, isSelected) => {
      setSelectedActivities(prev => {
        const updatedActivities = isSelected ? [...prev, activity] : prev.filter(item => item !== activity);
        let uniqueVals = [...new Set(updatedActivities)];
        chosenActivities = uniqueVals
        console.log('Updated Activities:', chosenActivities); // Print the resulting array

        return updatedActivities;
      });
    },[]);
    return (
      
        <Grid width={{base:"80vw","md":"60vw"}} templateColumns={{sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)"}} gap={4}>
        {options.map((option) => (
          <ActivityCard 
            key={`option-${option}`}
            value={option}
            icon={option}
            onChange={handleActivityChange} // Pass the callback to handle state change
          >
            {option}
          </ActivityCard>
        ))}
      </Grid>
     
    );
  };
  // end of activities



  const handleSliderChange = (value) => {
    let level;
    if (value === 0) {
      level = "Low"
    }
    if (value === 1) {
      level = "Medium"
    }
    if (value == 2) {
      level = "High"
    }
    setHiddenGemLevel(level)
    setSliderValue(value);
  };


  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleGenerateItinerary = async () => {


    try {

      const formattedActivities = formatActivitiesList(chosenActivities);
      const unfilledFields = [];
      if (chosenActivities.length == 0) {
        unfilledFields.push('Activities');
      }

      if (unfilledFields.length > 0) {
        setErrorMessage(`Please fill in the following fields:\n ${unfilledFields.join('\n ')}`);
        setErrorDialogOpen(true);
        return;
      }


      if (additionalInfo == '') {
        additionalInfo = "None"
      }

      updateTravelData({
        activities: formattedActivities,
        activitiesPerDay: packedness,
        customActivities: ""
      });



      history('/gems');


     


    } catch (error) {
      console.error('Error generating itinerary:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      // Hide shimmer effect when the API response is received
      setLoading(false);
    }
  };

  
  return (
    <div>
    {/* // <div className={styles.container}> */}
      <Container>
      <Container centerContent mt="2vw">
      <Heading mb="2vw" mt="2vw" as="h1" style={{ marginTop: '1vw'}} fontSize={{ base: "xl", md: "3xl" }}>{mainTitle}</Heading>

      <Text mb="3vw" className='customgray2' fontSize="1.125rem" lineHeight="1.75rem">
      Let our AI know what kind of things you'd like to do on your trip, and we'll suggest the best sights for you!
      </Text>
      </Container>


      {/* Activities */}
      <div className={styles.inputGroup}>

       <Container centerContent mb="4vw">
       <ActivitiesSelection></ActivitiesSelection>
       </Container>

</div>

<Box width="300px" mx="auto" mt="4" mb="3vw" display={chosenActivities.length > 0 ? "block": "none"}>
      <Popover>
        <PopoverTrigger>
          <FormLabel mt="2vw" display="flex" alignItems="center">
            Hidden Gem Level:
            <IconButton
              icon={<InfoOutlineIcon />}
              aria-label="Info"
              variant="ghost"
              colorScheme="teal"
              size="sm"
              ml="2"
            />
          </FormLabel>
        </PopoverTrigger>
        <PopoverContent>
          <Container centerContent>
            
          <PopoverHeader fontSize={useBreakpointValue({ base: "4vw", md: "1vw" })}>Explanation</PopoverHeader>
          <PopoverCloseButton />

          </Container>
          <PopoverBody whiteSpace="pre-wrap">
          <Text>Hidden gems are less-known destinations that offer unique and authentic experiences for travelers.</Text>
          <Text>This slider specifies the extent to which the activities will be hidden gems:</Text>
          <Text><b>Low</b>: A few hidden gem activities will be in the itinerary.</Text>
          <Text><b>Medium</b>: A mix of hidden gem activities and more typical activities will be in the itinerary.</Text>
          <Text><b>High</b>: Mainly hidden gem activities will be in the itinerary</Text>



            </PopoverBody>
        </PopoverContent>
      </Popover>

      <Slider value={sliderValue} min={0} max={2} step={1} onChange={handleSliderChange}>
        <SliderTrack>
          <SliderFilledTrack bg="green" />
        </SliderTrack>
        <SliderThumb boxSize={6} bg="green" />
      </Slider>

      <Box display="flex" justifyContent="space-between" mt="2">
        <Box>Low</Box>
        <Box>Medium</Box>
        <Box>High</Box>
      </Box>
    </Box>
      {/* Number of activities per day */}
      <div className={styles.inputGroup}>
      <FormLabel id='fonts' mb="2vw">How packed do you want your trip? <Text as='span' fontSize={{"base":"0.9rem","md":"1rem"}} style={{fontWeight: "normal"}} id='optional' ml="0.3vw"> (activities per day)</Text></FormLabel>


      <ActivitiesPerDaySelection></ActivitiesPerDaySelection>

      </div>
      

      <Container centerContent>
      <Button
      id='shadow'
            style={{ marginBottom: '2vw', marginTop: '1vw' }}
            color='white'
            borderRadius="9999px"
            backgroundColor="black"
            _hover={{color: "white",backgroundColor: "black"}}
            fontWeight="500"
            onClick={handleGenerateItinerary}
          >
            Next
          </Button>
          

      </Container>
      <AlertDialog
        isOpen={errorDialogOpen}
        onClose={handleCloseErrorDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
                maxW={{ base: '90%', md: 'md' }} // Adjust the width based on your preference
                w={{ base: '100%', md: 'auto' }}

          
          >
            <AlertDialogHeader fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
             <Container centerContent>
             
             <Text fontSize={{ base: '1rem', md: '1.2rem', }} id='fonts'>Oops, You still have unfilled fields!</Text>
             </Container>
            </AlertDialogHeader>

            <AlertDialogBody>
  {errorMessage && (
    <Container centerContent>
      <ul>
      {errorMessage.split('\n').map((message, index) => (
        <li key={index} style={{ listStyle: index === 0 ? 'none' : 'initial'}}>
          {index === 0 ? (
            <VisuallyHidden>{message}</VisuallyHidden>
            // <Text mb="0.5vw" fontSize="1.2vw">{message}</Text>
          ) : (
            <Text>{message}</Text>
          )}
        </li>
      ))}
    </ul>
    </Container>
  )}
</AlertDialogBody>



<AlertDialogFooter>
             <Container centerContent>
             <Button color='white'
             borderRadius="9999px"
            backgroundColor="black"
            _hover={{color: "white",backgroundColor: "black"}}
            fontWeight="500" onClick={handleCloseErrorDialog} ml={3}>
                Return
              </Button>
             </Container>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
      </Container>
      <FooterSection></FooterSection>
      </div>
  );
};


export default ActivitiesPage;
