import React from 'react';
import { Box, Button, Flex, Text, Image, HStack } from '@chakra-ui/react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const CloneItineraryBanner = ({handleClone}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" padding="20px" border="1px solid rgb(238 238 238)" size="md" background="rgb(250 250 250)">
      <Text fontSize="md" fontWeight="1rem" w="50%">
        To edit this itinerary, make a clone and personalise it to suit your needs!
      </Text>
      <HStack>
      <Button borderRadius="9999px" onClick={handleClone} color="black" bg="white" fontWeight="500" _hover={{ bg: 'white' }} border="1px solid rgb(238 238 238)">
      <FontAwesomeIcon size='15' icon={faCopy}  color="black" />


        <Text  ml={2}>
        Clone Itinerary
            </Text>
      </Button>
      </HStack>

    </Flex>
  );
};

export default CloneItineraryBanner;