import React, { useEffect, useState } from 'react';
import { Box, Link, Button, useBreakpointValue, Flex, IconButton, Text,Icon } from '@chakra-ui/react';
import { faFilePdf, faShare, faTrashCan,faArrowLeft,faLocationDot, faG } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareComponent from './ShareComponent';


const ItineraryNavbar = ({saveItinerary,deleteItinerary,exportToMaps,coordsReady}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Flex 
      as="nav"
      align="center"
      justify="space-between"
      p="2"
      position="fixed"
      top="0"
      left="0"
      right="0"
      width={{base:"100%",md:"50%"}}
      zIndex="10"
      bg={isScrolled ? 'white' : 'transparent'}
      transition="background-color 0.3s"
      borderBottomWidth={isScrolled ? '1px' : '0'}
      border={isScrolled ? "1px solid rgb(224 224 224)":"none" }
    >
      <Flex align="center" ml={{base:2,md:"10"}} mt={2}>
        <Link mb={1} href="/itinerary" borderRadius="md" px="2" py={1} fontSize="base" bg={isScrolled ? "rgb(245 245 245)" : "hsla(0,0%,100%,.2)"} color={isScrolled ? 'black' : 'white'} _hover={isScrolled ? { bg: 'rgb(238 238 238)', color: "black" } : { bg: 'hsla(0,0%,100%,.5)', color: "white" }}>
          <Icon  as={FontAwesomeIcon} icon={faArrowLeft} />
          
        </Link>
        <Text fontWeight="500" style={{"textShadow":"0 0 0 white !important"}} fontSize="xl" ml="4" color={isScrolled ? 'black' : 'white'}>
          PathPal
        </Text>
      </Flex>
      <Flex mr="4">
      {coordsReady && <Button onClick={exportToMaps} mr={2} bg={isScrolled ? "rgb(245 245 245)" : "hsla(0,0%,100%,.2)"} color={isScrolled ? 'black' : 'white'} _hover={isScrolled ? { bg: 'rgb(238 238 238)', color: "black" } : { bg: 'hsla(0,0%,100%,.5)', color: "white" }} borderRadius="9999px">
            <FontAwesomeIcon size='15' icon={faG} style={{
              marginBottom: "0.1vh"
            }}  color={isScrolled ? 'black' : 'white'} />
            {!isMobile && (
              <Text ml={1}>
              Maps
              </Text>
            )}
            </Button>}

            <ShareComponent isScrolled={isScrolled}></ShareComponent>


  <IconButton
    aria-label="PDF"
    bg={isScrolled ? "rgb(245 245 245)" : "hsla(0,0%,100%,.2)"} color={isScrolled ? 'black' : 'white'} _hover={isScrolled ? { bg: 'rgb(238 238 238)', color: "black" } : { bg: 'hsla(0,0%,100%,.5)', color: "white" }}
    icon={ <FontAwesomeIcon icon={faFilePdf}  color={isScrolled ? 'black' : 'white'}/> }
    isRound={true}
    onClick={saveItinerary}
  />

  {deleteItinerary != "Guest" && (
    <>
    <IconButton
    aria-label="Delete"
    bg={isScrolled ? "rgb(245 245 245)" : "hsla(0,0%,100%,.2)"} color={isScrolled ? 'black' : 'white'} _hover={isScrolled ? { bg: 'rgb(238 238 238)', color: "black" } : { bg: 'hsla(0,0%,100%,.5)', color: "white" }}
    icon={<FontAwesomeIcon icon={faTrashCan} color={isScrolled ? 'black' : 'white'}/>}
    isRound={true}
    ml={2}
    onClick={deleteItinerary}
  />
    </>
  )}
      </Flex>
    </Flex>
  );
};

export default ItineraryNavbar;