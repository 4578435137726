import {
    Box,
    Container,
    Image,
    Text,
    Grid,
    GridItem,
    VStack,
    HStack,
    useBreakpointValue,
    Spacer,
    Divider
  } from '@chakra-ui/react';
  import React from 'react';


  const Why = () => {
    return (
        <>
 <Box textAlign="center" mb={{base:"3vh",md:"3vh"}} fontSize={{ base: "1.875rem", md: "2.25rem" }} lineHeight="1.75rem" >
 <Text as="span" color="black">The </Text>
    <Text as="span" color="#C8A0FF">only tool</Text>
    <Text as="span" color="black">  you'll need</Text>

</Box>
<Container textAlign="center" maxW={{base: "100vw", md:"40vw"}}>
<Text fontSize={{ base: "1.125rem", md: "1.125rem" }} lineHeight="1.75rem" className='tshad' color="#9e9e9e">Say goodbye to the stress of planning and hello to personalized recommendations, efficient itineraries & magical hidden gem experiences.</Text>

</Container>
<Container maxW="75vw" mt="5rem">
      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={10} alignContent="center">
        {/* Start of the first Grid Item */}
        <GridItem w="100%">
          <HStack align="start" spacing={4}>
            <Text fontSize="1.75rem" lineHeight="2rem" pt={2}>🗺️</Text>
            <VStack align="start" spacing={1}>
              <Text fontSize="lg" fontWeight="500" lineHeight="1.75rem" className='tshad'>
                Optimal Route Planning
              </Text>
              <Text color="#9e9e9e" fontSize="1rem" fontWeight="500" className='tshad'>
                Our AI trip planner analyzes your preferences to craft the most efficient route, saving you time and effort.
              </Text>
            </VStack>
          </HStack>
        </GridItem>
        {/* End of the first Grid Item */}
        {/* Repeat similar structure for other Grid Items */}
        <GridItem w="100%">
          <HStack align="start" spacing={4}>
            <Text  fontSize="1.75rem" lineHeight="2rem" pt={2}>🧑‍💻</Text>
            <VStack align="start" spacing={1}>
              <Text fontSize="lg" fontWeight="500" lineHeight="1.75rem" className='tshad'>
                Personalize Your Adventure
              </Text>
              <Text color="#9e9e9e" fontSize="1rem" className='tshad' lineHeight="1.75rem">
                Shape your journey by freely adding, editing, or deleting activities from your itinerary.
              </Text>
            </VStack>
          </HStack>
        </GridItem>
        <GridItem w="100%" mt={10}>
          <HStack align="start" spacing={4}>
            <Text  fontSize="1.75rem" lineHeight="2rem" pt={2}>📊</Text>
            <VStack align="start" spacing={1}>
              <Text fontSize="lg" fontWeight="500" lineHeight="1.75rem" className='tshad'>
                Online Data Insights
              </Text>
              <Text color="#9e9e9e" fontSize="1rem" className='tshad'  lineHeight="1.75rem">
                Harness the power the internet by using our AI trip planner to get insider tips & insights from the web and into your plans.
              </Text>
            </VStack>
          </HStack>
        </GridItem>
        <GridItem w="100%" mt={10}>
          <HStack align="start" spacing={4}>
            <Text  fontSize="1.75rem" lineHeight="2rem" pt={2}>💎</Text>
            <VStack align="start" spacing={1}>
              <Text fontSize="lg" fontWeight="500" lineHeight="1.75rem" className='tshad'>
                Local Spot Recommendations
              </Text>
              <Text color="#9e9e9e" fontSize="1rem" className='tshad'  lineHeight="1.75rem">
                Discover local spots and hidden gems recommended by our AI trip planner, tailored to your activity preferences.
              </Text>
            </VStack>
          </HStack>
        </GridItem>
      </Grid>
    </Container>
        </>
    )
  }

  export default Why