import {
    Box,
    Image,
    Text,
    Link,
    SimpleGrid,
    Container,
  } from '@chakra-ui/react';
  import React from 'react';
  import gyg from './gyg.svg'
  import tqets from './tqets.svg'
  import klok from './klook.svg'
  import expedia from './expedia.svg'
import { Divider } from 'rsuite';


  const Partners = () => {
    return (
        <>
       <Box textAlign="center">
       <Text fontSize={{base:"2rem",md:"3rem"}} lineHeight={1}>Our Trusted Partners</Text>
       <Container>
       <Divider width="2px"></Divider>
       </Container>
       </Box>
       <Box mx={1} my={2} mt={4} display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
      <SimpleGrid columns={[2, null, 4]} spacing={{base:"1rem",md:"2rem"}} justifyContent="center" alignItems="center" w={{base: "80vw", md:"40vw"}}>
        <Link href="https://www.getyourguide.com?partner_id=R5ZYLDX&utm_medium=travel_agent" isExternal>
          <Image src={gyg} alt="Get your guide AI Trip planner travel trip planner ai" w="40" htmlWidth="200" htmlHeight="80" loading="lazy" />
        </Link>
        <Link href="https://www.tiqets.com/en/search?partner=pathpal" isExternal>
          <Image src={tqets} alt="Tiqets AI Trip planner travel trip planner ai" ml={5} w="24" htmlWidth="130" htmlHeight="50" loading="lazy" />
        </Link>
        <Link href="https://expedia.com/affiliate?siteid=1&camref=1101lvV4N&creativeref=1100l68075&landingPage=https://www.expedia.com/" isExternal>
          <Image src={expedia} ml={5} alt="Expedia AI Trip planner travel trip planner ai" w="24" htmlWidth="80" htmlHeight="80" loading="lazy" />
        </Link>
        <Link href="https://affiliate.klook.com/redirect?aid=36746&aff_adid=716782&k_site=https://www.klook.com/" isExternal>
          <Image src={klok} alt="Klook AI Trip planner travel trip planner ai" w="full" htmlWidth="150" htmlHeight="100" loading="lazy" />
        </Link>
      </SimpleGrid>
    </Box>
        </>
    )
  }

export default Partners