import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { HStack, VisuallyHidden, chakra } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid';
import FooterSection from './landing/FoooterSection'



import 'rsuite/dist/rsuite.min.css';
import { AlertDialog, Box,Slider, Tooltip, Popover, PopoverCloseButton, Icon, useCheckbox, PopoverTrigger,PopoverContent, PopoverHeader, PopoverBody, IconButton, SliderTrack,SliderFilledTrack, SliderThumb, AlertDialogBody, Text, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from "@chakra-ui/react";
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { Container, FormLabel, Heading, Input, Button, useBreakpointValue } from '@chakra-ui/react';


import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTravel } from './TravelContext';

import { faCheckCircle, faShoppingBag, faLandmark, faTree, faGem, faHandSparkles, faChildReaching, faSeedling,faScroll,faCloudSun,faMonument,faPalette, faMartiniGlassCitrus,faLocationDot } from '@fortawesome/free-solid-svg-icons';


const activityIcons = {
  'Historical Sites': faScroll,
  'Shopping': faShoppingBag,
  'Cultural Landmarks': faMonument,
  'Outdoor Adventures': faCloudSun,
  'Must-see Attractions': faLocationDot,
  'Nature & Parks': faTree,
  "Arts & Theatre": faPalette,
  "Nightlife & Entertainment": faMartiniGlassCitrus,
  "Museums": faLandmark,
  "Botanical Exploration": faSeedling,
  "Kid-friendly": faChildReaching,
  "Obscure Places": faHandSparkles
};


const HiddenGems = () => {
  
  const history = useNavigate(); // Get the history object from React Router
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading,setLoading] = useState(false);
  const [hiddenGemLevel, setHiddenGemLevel] = useState("Low");
  let [additionalInfo, setAdditionalInfo] = useState("")
  const { travelData, updateTravelData, resetTravelData } = useTravel();
  const title = `Hidden gems preferences`
  
  useEffect(() => {
    if (travelData.hiddenGemLevel) {
      if (JSON.parse(localStorage.getItem('profile'))) {
        resetTravelData()
        history('/itinerary')
      } else {
        history('/new-trip')
      }
    }
    if (travelData.activities.length == 0) {
      if (JSON.parse(localStorage.getItem('profile'))) {
        resetTravelData()
        history('/itinerary')
      } else {
        history('/new-trip')
      }
    }
  }, [])
  


  const SliderMarks = ({ min, max, step, sliderValue }) => {
    const numberOfMarks = (max - min) / step;
  
    const getMarkStyle = (markIndex) => {
      let style = {
        position: 'absolute',
        width: '1px', // Width of the mark
        height: '10px', // Height of the mark
        backgroundColor: 'black',
        transform: 'translate(-50%, -50%)', // Centering the mark relative to its position
        zIndex: '1', // Ensure it's layered properly
        left: `${(100 / numberOfMarks) * markIndex}%`
      };
  
      if (sliderValue === markIndex) {
        style.display = 'none'; // Hide if the thumb is over the line
      } else if (sliderValue > markIndex) {
        style.backgroundColor = 'black'; // Has been passed by the thumb
      } else {
        style.backgroundColor = '#bfbfbf'; // Default state, not yet reached by the thumb
      }
  
      return style;
    };
  
    const marks = [];
    for (let i = 0; i <= numberOfMarks; i++) {
      marks.push(
        <Box key={`mark-${i}`} sx={getMarkStyle(i)} />
      );
    }
  
    return marks;
  };


  const [isDragging, setIsDragging] = useState(false); // State to manage drag state
  const [isHovering, setIsHovering] = useState(false); // State to manage hover state

  const renderGemIcons = (value) => {
    let icons = [];
    // Apply margin only if value > 0, meaning there will be more than one icon
    const iconStyle = value > 0 ? { marginRight: '0.15vw' } : {};
  
    for (let i = 0; i <= value; i++) {
      icons.push(
        // Apply the iconStyle only to icons except the last one
        <FontAwesomeIcon key={i} icon={faGem} color="white" style={i < value ? iconStyle : {}} />
      );
    }
    return icons;
  };


  
  const [sliderValue, setSliderValue] = useState(0); // Initial value, can be 0, 1, or 2 for Low, Medium, High
  

  const handleSliderChange = (value) => {
    let level;
    if (value === 0) {
      level = "Low"
    }
    if (value === 1) {
      level = "Medium"
    }
    if (value == 2) {
      level = "High"
    }
    setHiddenGemLevel(level)
    setSliderValue(value);
  };


  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleGenerateItinerary = async () => {
    
    // Show shimmer effect while waiting for the API response
    setLoading(true);

    try {
      // Make the OpenAI API request (unchanged code)
      if (additionalInfo == '') {
        additionalInfo = "None"
      }

      updateTravelData({
        hiddenGemLevel: hiddenGemLevel,
        additionalInfo: additionalInfo
      });
      const id = uuidv4(); // Generate a unique UUID
      history(`/itinerary/${id}`);





    } catch (error) {
      console.error('Error generating itinerary:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      // Hide shimmer effect when the API response is received
      setLoading(false);
    }
  };

  
  return (
    <div>
      <Container mt="2vw">
        <Container centerContent>
      <Heading mb="2vw" mt="2vw" as="h1" style={{ marginTop: '1vw'}} fontSize={{ base: "xl", md: "3xl" }}>{title}</Heading>
      </Container>
      <Text mb="3vw" color="#757575" fontSize="1.125rem" lineHeight="1.75rem">
      Select the level of hidden gems you would like to include in your trip and any extra preferences worth mentioning.
      </Text>
      {/* <Text mb="3vw" className='customgray2' fontSize="1.25rem" lineHeight="1.75rem">
      Hidden Gem Level
      </Text> */}
     
      <Box mt="4" mb="3vw">
      <HStack spacing={0}>
      <FormLabel className='tshad' color="black" fontSize="1.25rem" mt="1vw" display="flex">
            Hidden Gems Level
            </FormLabel>

      <Popover>
        <PopoverTrigger>
          
            <IconButton
              icon={<InfoOutlineIcon />}
              aria-label="Info"
              variant="ghost"
              colorScheme="teal"
              size="sm"
              mt={{base: "0vh",md: "1vh"}}
              mb={{base: "1vh",md: "0vh"}}
            />
        </PopoverTrigger>
        <PopoverContent>
          <Container centerContent>
            
          <PopoverHeader fontSize={useBreakpointValue({ base: "4vw", md: "1vw" })}>Explanation</PopoverHeader>
          <PopoverCloseButton />

          </Container>
          <PopoverBody whiteSpace="pre-wrap">
          <Text>Hidden gems are less-known destinations that offer unique and authentic experiences for travelers.</Text>
          <Text>This slider specifies the extent to which the activities will be hidden gems:</Text>
          <Text><b>Low</b>: A few hidden gem activities will be in the itinerary.</Text>
          <Text><b>Medium</b>: A mix of hidden gem activities and more typical activities will be in the itinerary.</Text>
          <Text><b>High</b>: Mainly hidden gem activities will be in the itinerary</Text>



            </PopoverBody>
        </PopoverContent>
      </Popover>
      </HStack>
      <Text mb="5vh" color="#757575" fontSize="1rem" lineHeight="1.5rem" fontWeight="500">Depending on the hidden gems level, we will select more or less hidden gem activities.</Text>

      <Container>
      <>
      <Slider value={sliderValue} min={0} max={2} step={1}
      onChange={handleSliderChange}
      // Handlers for dragging state
      onChangeStart={() => setIsDragging(true)}
      onChangeEnd={() => setIsDragging(false)}
      >
              <SliderMarks className="custom-thumb" min={0} max={2} step={1}  sliderValue={sliderValue} />

               

        <SliderTrack backgroundColor="#bfbfbf" opacity={sliderValue > 0 ? "1": "0.5"} h="0.1rem"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        position="relative"
        >
          <SliderFilledTrack bg="black" />
        </SliderTrack>
        <Tooltip
        backgroundColor="black"
        borderRadius="9999px"
            hasArrow
            isOpen={isDragging || isHovering}
             label={renderGemIcons(sliderValue)}
            placement="top"
          >
            <SliderThumb
              boxShadow="rgba(0, 0, 0, 0.1) 0px 3px 1px, rgba(0, 0, 0, 0.13) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 0px 0px 1px"
              boxSize={6}
              bg="#DCFFA0"
              border="2px solid black"
              mr="2vw"
              // Handlers for hover state
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              </SliderThumb>
          </Tooltip>
      </Slider>

      <Box display="flex" justifyContent="space-between" mt="1">
        <Box>Low</Box>
        <Box>Medium</Box>
        <Box>High</Box>
      </Box>

      </>
      </Container>
    </Box>
      </Container>


      <Container>
        {/* hidden gem level */}
      <Box mx="auto" mt="4" mb="1.5vw">
      <FormLabel mt="2vw" display="flex" alignItems="center" className='tshad' color="black" fontSize="1.25rem">Any extra points worth mentioning? <Text as='span' fontSize={{"base":"0.9rem","md":"1rem"}} style={{fontWeight: "normal"}} id='optional' ml="0.3vw"> (Optional)</Text>
</FormLabel>
<Text mb="2vw" color="#757575" fontSize="1rem" lineHeight="1.5rem" fontWeight="500">Specify any additional preferences that want to be considered.</Text>

   

  <Input placeholder="I want to visit the Eiffel Tower. I have 2 kids" value={additionalInfo} onChange={(e) => {
    setAdditionalInfo(e.target.value)
  }} isFullWidth />


    </Box>
      </Container>
      

      <Container centerContent>
        {loading ? (
          <InfinitySpin
  visible={true}
  width="200"
  color="#4fa94d"
  ariaLabel="infinity-spin-loading"
  />
        ) : (
          <Button
          id='shadow'
          style={{ marginBottom: '2vw', marginTop: '1vw' }}
          color='white'
          borderRadius="9999px"
          backgroundColor="black"
          _hover={{color: "white",backgroundColor: "black"}}
          fontWeight="500"
            onClick={handleGenerateItinerary}
          >
            Generate
          </Button>
        )}
      </Container>
      <AlertDialog
        isOpen={errorDialogOpen}
        onClose={handleCloseErrorDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
                maxW={{ base: '90%', md: 'md' }} // Adjust the width based on your preference
                w={{ base: '100%', md: 'auto' }}

          
          >
            <AlertDialogHeader fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
             <Container centerContent>
             
             <Text fontSize={{ base: '1rem', md: '1.2rem' }}>Oops, You still have unfilled fields!</Text>
             </Container>
            </AlertDialogHeader>

            <AlertDialogBody>
  {errorMessage && (
    <Container centerContent>
      <ul>
      {errorMessage.split('\n').map((message, index) => (
        <li key={index} style={{ listStyle: index === 0 ? 'none' : 'initial'}}>
          {index === 0 ? (
            <VisuallyHidden>{message}</VisuallyHidden>
            // <Text mb="0.5vw" fontSize="1.2vw">{message}</Text>
          ) : (
            <Text>{message}</Text>
          )}
        </li>
      ))}
    </ul>
    </Container>
  )}
</AlertDialogBody>



            <AlertDialogFooter>
             <Container centerContent>
             <Button colorScheme="green" onClick={handleCloseErrorDialog} ml={3}>
                Return
              </Button>
             </Container>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
            <FooterSection></FooterSection>
      </div>
  );
};


export default HiddenGems;
